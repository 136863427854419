import { useEffect, useState } from "react";
import Axios from "axios";
import { config } from "../config/config";

export const UsePaymentInfo = (postUid) => {
  const { PAYMENT_INFO } = config();

  const [paymentInfo, setPaymentInfo] = useState({
    isError: false,
    isLoading: true,
    paymentInfoData: null,
  });

  useEffect(() => {
    if (postUid) {
      (async () => {
        try {
          // throw new Error("Test Error while fetching payment info");
          const { data } = await Axios.post(
            PAYMENT_INFO,
            { postUid },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          data?.items.sort((a, b) => b.created_at - a.created_at);
          setPaymentInfo({
            ...paymentInfo,
            isLoading: false,
            paymentInfoData: data,
          });
        } catch (error) {
          console.error(
            "error while fetching payment info - use-payment-info.js - mylogs :",
            error
          );
          setPaymentInfo({
            ...paymentInfo,
            isLoading: false,
            isError: true,
          });
        }
      })();
    } else {
      setPaymentInfo({
        ...paymentInfo,
        isLoading: false,
        isError: true,
      });
    }
  }, []);

  return { paymentInfo, setPaymentInfo };
};
