import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export const UsePostDataInfo = (postData, postUid) => {
  const [postDataRes, setPostDataRes] = useState({
    isError: false,
    isLoading: false,
    isEmpty: false,
    postData: postData,
  });

  useEffect(() => {
    if (postData) {
      setPostDataRes({
        ...postDataRes,
        isLoading: false,
      });
      return;
    }

    if (!postUid) {
      setPostDataRes({
        ...postDataRes,
        isError: true,
        isLoading: false,
      });
      return;
    }

    (async () => {
      try {
        setPostDataRes({
          ...postDataRes,
          isLoading: true,
        });
        // throw new Error("No post data found - ViewProperty.js - mylogs!");
        const docRef = doc(firestoreDb, `posts/${postUid}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPostDataRes({
            ...postDataRes,
            isLoading: false,
            postData: docSnap.data(),
          });
        } else {
          setPostDataRes({
            ...postDataRes,
            isEmpty: true,
            isLoading: false,
          });
        }
      } catch (err) {
        console.log(
          "err while fetching post data - ViewProperty.js - mylogs :",
          err
        );
        setPostDataRes({
          ...postDataRes,
          isError: true,
          isLoading: false,
        });
      }
    })();
  }, []);

  return { postDataRes };
};
