import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "@firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { ProfileContext } from "./ProfileContextProvider";
import { useLocation } from "react-router";

export const MyPostsContext = createContext();

export const MyPostsContextProvider = ({ children }) => {
  const { user } = useContext(ProfileContext);
  const { userInfo, isUserSignedIn } = user;

  const [myPostsRes, setMyPostsRes] = useState({
    isError: false,
    isLoading: true,
    myPostsData: null,
  });
  const [triggerMyPostsRes, setTriggerMyPostsRes] = useState(false);
  const [triggerMyPosts, setTriggerMyPosts] = useState(false);

  useEffect(() => {
    (async () => {
      if (isUserSignedIn && triggerMyPostsRes) {
        try {
          // throw new Error("Error getting dashboard documents:");
          const q = query(
            collection(firestoreDb, "posts"),
            where("ownerUid", "==", userInfo?.uid),
            where("paymentStatus", "in", ["success", "failed", "invalid"]),
            orderBy("postTime", "desc")
          );
          const querySnapshot = await getDocs(q);
          const myPostsDataRes = [];
          querySnapshot.forEach((doc) => {
            myPostsDataRes.push(doc.data());
          });
          setMyPostsRes({
            isError: false,
            isLoading: false,
            myPostsData: myPostsDataRes,
          });
        } catch (err) {
          console.error(
            "err while getting my posts - FilterActionSheet.js - mylogs :",
            err
          );
          setMyPostsRes({
            isError: true,
            isLoading: false,
            myPostsData: null,
          });
        }
      } else {
        setMyPostsRes({
          isError: false,
          isLoading: true,
          myPostsData: null,
        });
      }
    })();
  }, [user, triggerMyPostsRes, triggerMyPosts]);

  return (
    <>
      <MyPostsContext.Provider
        value={{
          myPostsRes,
          triggerMyPostsRes,
          setTriggerMyPostsRes,
          triggerMyPosts,
          setTriggerMyPosts,
        }}
      >
        {children}
      </MyPostsContext.Provider>
    </>
  );
};
