import React, { createContext, useContext, useEffect, useState } from "react";

const ZoomContext = createContext();

export const ZoomProvider = ({ children }) => {
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);

  useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (isZoomEnabled) {
      viewport.setAttribute("content", "width=device-width, initial-scale=1.0");
    } else {
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      );
    }
  }, [isZoomEnabled]);

  return (
    <ZoomContext.Provider value={{ isZoomEnabled, setIsZoomEnabled }}>
      {children}
    </ZoomContext.Provider>
  );
};

export const useZoom = () => {
  return useContext(ZoomContext);
};
