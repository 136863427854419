import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { floors, localities } from "../constants/constants";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import { Info } from "@mui/icons-material";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { convertFirebaseTimestamp } from "../utils/generals";

// Diabled keyboard popup for date picker
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input {...props} ref={ref} className="custom-date-picker-input" readOnly />
  );
});

export const InfoTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  const [postTypeInfoModal, setPostTypeInfoModal] = useState({
    isDirectCallingOpen: false,
    isRequestSubmissionOpen: false,
  });

  return (
    <>
      <TitleTextOkayModal
        value="isDirectCallingOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Direct Calling"
        text="People will directly call you if they are interested in your property."
      />
      <TitleTextOkayModal
        value="isRequestSubmissionOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Request Submission"
        text="People will submit a request form having basic details to you if they are interested in your property. Once you approve the request then only you both can contact each other."
      />
      <Box>
        <Stack spacing={1.5}>
          <FieldHeading heading="Post Type" />
          <Stack direction="row" spacing={3} alignItems="center">
            <Box sx={{ position: "relative" }}>
              <PostTypeChip
                isEditPost={isEditPost}
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Direct Calling"
                value="direct-calling"
              />
              {!isEditPost && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-20px",
                    height: "0px",
                  }}
                  color="black"
                  onClick={() =>
                    setPostTypeInfoModal({
                      ...postTypeInfoModal,
                      isDirectCallingOpen: true,
                    })
                  }
                >
                  <Info
                    sx={{ fontSize: "26px", color: `${primaryColor[800]}` }}
                  />
                </IconButton>
              )}
            </Box>
            <Box sx={{ position: "relative" }}>
              <PostTypeChip
                isEditPost={isEditPost}
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Request Submission"
                value="request-submission"
              />
              {!isEditPost && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-20px",
                    height: "0px",
                  }}
                  color="inherit"
                  disabled={isEditPost}
                  onClick={() =>
                    setPostTypeInfoModal({
                      ...postTypeInfoModal,
                      isRequestSubmissionOpen: true,
                    })
                  }
                >
                  <Info
                    sx={{ fontSize: "26px", color: `${primaryColor[800]}` }}
                  />
                </IconButton>
              )}
            </Box>
          </Stack>
        </Stack>
        <Spacer space={10} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Preference" />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              You can select multiple preference
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Family"
              value="family"
            />
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Bachelors"
              value="bachelors"
            />
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Students"
              value="students"
            />
          </Stack>
        </Stack>
        <Spacer space={30} />
        <Stack spacing={1} direction="row" alignItems="center">
          <FieldHeading heading="Available From" />
          <Box>
            <DatePicker
              showIcon
              dateFormat="dd/MM/yyyy"
              selected={convertFirebaseTimestamp(
                postPropertyInfo?.availableFrom
              )}
              onChange={(date) =>
                postPropertyInfoDispatch({
                  filter: "availableFrom",
                  value: date,
                })
              }
              disabledKeyboardNavigation={false}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              customInput={<CustomInput />}
            />
          </Box>
        </Stack>
        <Spacer space={25} />
        <Stack spacing={3} direction="row" alignItems="center">
          <FieldHeading heading="Area" />
          <OutlinedInput
            placeholder="Area"
            type="number"
            endAdornment={<InputAdornment position="end">Gajj</InputAdornment>}
            value={postPropertyInfo?.area}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 3) {
                postPropertyInfoDispatch({
                  filter: "area",
                  value,
                });
              }
            }}
            sx={{
              ...removingDigitsIndicatorsStyle,
              width: "34%",
              height: "35px",
              backgroundColor: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
            }}
          />
        </Stack>
        <Spacer space={25} />
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1} direction="row" alignItems="center">
            <FieldHeading heading="Block" />
            <TextField
              type="text"
              size="small"
              variant="outlined"
              placeholder="#"
              inputProps={{ maxLength: 3 }}
              sx={{
                ...textInputFieldStyle,
                width: "60px",
              }}
              value={postPropertyInfo?.block}
              onChange={(event) =>
                postPropertyInfoDispatch({
                  filter: "block",
                  value: event.target.value.toUpperCase(),
                })
              }
            />
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <FieldHeading heading="Floor" />
            <Select
              displayEmpty
              value={postPropertyInfo?.floor}
              onChange={(event) => {
                postPropertyInfoDispatch({
                  filter: "floor",
                  value: event.target.value,
                });
              }}
              input={<OutlinedInput />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 68 * 4.5 + 8,
                  },
                },
              }}
              sx={{
                ...selectInputFieldStyle,
                width: "100px",
                height: "35px",
              }}
            >
              <MenuItem value="">
                <Typography fontStyle="italic">Select</Typography>
              </MenuItem>
              {floors.map((floor) => (
                <MenuItem
                  key={floor}
                  value={floor}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: `${primaryColor[200]}`,
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: `${primaryColor[200]}`,
                    },
                  }}
                >
                  {floor}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <FieldHeading heading="Located At" />
          <Select
            disabled={isEditPost}
            displayEmpty
            value={postPropertyInfo?.locality}
            onChange={(event) => {
              postPropertyInfoDispatch({
                filter: "locality",
                value: event.target.value,
              });
            }}
            input={<OutlinedInput />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 68 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
            sx={{
              ...selectInputFieldStyle,
              width: "100%",
              height: "42px",
            }}
          >
            <MenuItem value="">
              <Typography fontStyle="italic" sx={{ color: grey[500] }}>
                Select
              </Typography>
            </MenuItem>
            {localities.map((name) => (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: `${primaryColor[200]}`,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: `${primaryColor[200]}`,
                  },
                }}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <FieldHeading heading="Near By" />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            placeholder="Near by any landmark"
            sx={{ ...textInputFieldStyle }}
            inputProps={{ maxLength: 101 }}
            value={postPropertyInfo?.nearBy}
            onChange={(event) =>
              postPropertyInfoDispatch({
                filter: "nearBy",
                value: event.target.value,
              })
            }
          />
        </Stack>
      </Box>
    </>
  );
};

export const PostTypeChip = ({
  isEditPost,
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      disabled={isEditPost}
      label={label}
      color="primary"
      variant={postPropertyInfo?.postType === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "postType",
          value: `${postPropertyInfo?.postType === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.postType === value ? "white" : grey[900]}`,
        backgroundColor: `${
          postPropertyInfo?.postType === value ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const PreferenceChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={
        postPropertyInfo?.preference?.includes(value) ? "filled" : "outlined"
      }
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "preference",
          value: postPropertyInfo?.preference?.includes(value)
            ? [
                ...postPropertyInfo?.preference?.filter(
                  (item) => item !== value
                ),
              ]
            : [...postPropertyInfo?.preference, value],
        })
      }
      sx={{
        color: `${
          postPropertyInfo?.preference?.includes(value) ? "white" : grey[900]
        }`,
        backgroundColor: `${
          postPropertyInfo?.preference?.includes(value) ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
  },
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
