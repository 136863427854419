import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";

export const MediaLoader = ({ uploadingMediaInfo }) => {
  return (
    <>
      <Box>
        <FullScreen>
          <Stack alignItems="center" spacing={2}>
            <CircularProgress sx={{ color: primaryColor[800] }} />
            <Spacer space={8} />
            <Typography>
              {uploadingMediaInfo?.category?.charAt(0).toUpperCase() +
                uploadingMediaInfo?.category?.slice(1)}{" "}
              image {uploadingMediaInfo?.UploadingMediaNumber}/
              {uploadingMediaInfo?.totalCategoryLength} is uploading{" "}
            </Typography>
            <Typography>progress {uploadingMediaInfo?.progress}%</Typography>
          </Stack>
        </FullScreen>
      </Box>
    </>
  );
};
