import { Box } from "@mui/material";
import {
  and,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";

export const UsePostrequestsInfo = (
  userUid,
  postUid,
  modalInfo,
  setModalInfo
) => {
  const [postRequestsRes, setPostRequestsRes] = useState({
    isLoading: true,
    isError: false,
    postRequestsData: {
      pending: [],
      approved: [],
      rejected: [],
    },
  });
  const [handlePostRequestStatus, setHandlePostRequestStatus] = useState({
    isError: false,
    isLoading: false,
  });
  const [triggerPostRequests, setTriggerPostRequests] = useState(false);

  const handlePostRequest = async (requestUid, status) => {
    setHandlePostRequestStatus({
      ...handlePostRequestStatus,
      isLoading: true,
    });
    setModalInfo({
      ...modalInfo,
      isOpen: false,
    });
    try {
      // throw new Error("Error while handling request");
      await updateDoc(doc(firestoreDb, `requests/${requestUid}`), {
        requestStatus: status,
      });
      setTriggerPostRequests(!triggerPostRequests);
      setModalInfo({
        isOpen: false,
        requestSatus: "",
        modalData: null,
      });
      setHandlePostRequestStatus({
        ...handlePostRequestStatus,
        isLoading: false,
      });
    } catch (err) {
      console.error(
        "err while handling request - PostRequests.js - mylogs :",
        err
      );
      setHandlePostRequestStatus({
        ...handlePostRequestStatus,
        isError: true,
      });
    }
  };

  const handleSeenRequest = async (requestUid) => {
    try {
      // throw new Error("Error while handling seen request");
      await updateDoc(doc(firestoreDb, `requests/${requestUid}`), {
        seen: true,
      });
    } catch (err) {
      console.error(
        "err while handling seen request - PostRequests.js - mylogs :",
        err
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // throw new Error("Error while getting my posts requests");
        const q = query(
          collection(firestoreDb, "requests"),
          and(
            where("ownerUid", "==", userUid),
            where("postUid", "==", postUid)
          ),
          orderBy("time", "desc")
        );
        const querySnapshot = await getDocs(q);
        const postRequestFilterRes = [];
        querySnapshot.forEach((doc) => {
          postRequestFilterRes.push(doc.data());
        });

        const pendingRequests = [];
        postRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Pending") {
            pendingRequests.push(doc);
          }
        });

        const approvedRequests = [];
        postRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Approved") {
            approvedRequests.push(doc);
          }
        });

        const rejectedRequests = [];
        postRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Rejected") {
            rejectedRequests.push(doc);
          }
        });

        setPostRequestsRes({
          isError: false,
          isPostRequestsResLoading: false,
          postRequestsData: {
            pending: pendingRequests,
            approved: approvedRequests,
            rejected: rejectedRequests,
          },
        });
      } catch (err) {
        console.error(
          "err while getting my posts request - FilterActionSheet.js - mylogs :",
          err
        );
        setPostRequestsRes({
          isError: true,
          isPostRequestsResLoading: false,
          postRequestsData: {
            pending: [],
            approved: [],
            rejected: [],
          },
        });
      }
    })();
  }, [triggerPostRequests]);

  return {
    postRequestsRes,
    handlePostRequestStatus,
    setHandlePostRequestStatus,
    handlePostRequest,
    handleSeenRequest,
  };
};
