import { useState, useRef, useReducer, useEffect } from "react";
import {
  Box,
  IconButton,
  Divider,
  Typography,
  Stack,
  Chip,
  Button,
  Radio,
  Select,
  MenuItem,
  OutlinedInput,
  Slider,
  TextField,
  Fab,
} from "@mui/material";
import { Tune, RestartAlt, Search } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Center } from "./Center";
import { Spacer } from "./Spacer";
import { AnimatePresence, motion } from "framer-motion";
import ActionSheet from "actionsheet-react";
import { MainBgColor, primaryBgColor, primaryColor } from "../utils/color";
import { localities } from "../constants/constants";

const initialMainFilterInfo = {
  sortByPrice: "desc",
  bhk: [],
  locality: [],
  housesPriceRange: [6000, 24000],
};

const mainFilterInfoReducer = (mainFilterInfo, action) => {
  switch (action.type) {
    case "reset":
      return initialMainFilterInfo;
    default:
      return { ...mainFilterInfo, [action.filter]: action.value };
  }
};

export const FilterActionSheet = ({ setMainFilterInfoProvider }) => {
  const ref = useRef();
  const [filterSytling, setFilterSytling] = useState({ display: "none" });
  const [isLocalitySelectOpen, setIsLocalitySelectOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const options = ["price", "bhk", "locality"];
  const [mainFilterInfo, mainFilterInfoDispatch] = useReducer(
    mainFilterInfoReducer,
    initialMainFilterInfo
  );

  const handleReset = () => {
    mainFilterInfoDispatch({
      type: "reset",
    });
    setMainFilterInfoProvider(null);
    document.body.style.overflow = "";
  };

  const handleSearch = async () => {
    ref.current.close();
    document.body.style.overflow = "";
    setMainFilterInfoProvider(mainFilterInfo);
  };

  useEffect(() => {
    const isAtBottom = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const scrollY = window.scrollY;
      return scrollY + windowHeight >= documentHeight - 150;
    };

    const scrolling = () => {
      const scrollTop = window.scrollY;
      if (!isAtBottom() && scrollTop > 175) {
        setFilterSytling({
          position: "fixed",
          bottom: "60px",
          right: "30px",
          zIndex: "99",
        });
      } else {
        setFilterSytling({ display: "none" });
      }
    };

    window.addEventListener("scroll", scrolling);

    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopstate = (event) => {
      ref.current.close();
      document.body.style.overflow = "";
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000); // Change text every 2 seconds
    return () => clearInterval(interval);
  }, [options.length]);

  return (
    <>
      <Box
        sx={filterSytling}
        onClick={() => {
          ref.current.open();
          document.body.style.overflow = "hidden";
        }}
      >
        <Fab color="primary">
          <Tune />
        </Fab>
      </Box>

      <ActionSheet
        ref={ref}
        onClose={handleReset}
        sheetStyle={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          height: "85%",
          overflowY: "scroll",
          backgroundColor: `${primaryBgColor}`,
        }}
        mouseEnable={false}
        touchEnable={false}
        sheetTransition="transform 0.2s ease-in-out"
        bgTransition="opacity 0.3s ease-in-out, z-index 0.3s ease-in-out"
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px="8px"
            bgcolor={primaryBgColor}
          >
            <Box></Box>
            <Typography
              fontSize="21px"
              fontWeight="600"
              color={primaryColor[900]}
            >
              Filters
            </Typography>
            <IconButton color="inherit" onClick={handleReset}>
              <RestartAlt sx={{ fontSize: "28px" }} />
            </IconButton>
          </Stack>
          <Divider />
          <Box sx={{ bgcolor: `${MainBgColor}`, overflowY: "scroll" }}>
            <Box sx={{ marginX: "15px" }}>
              <Spacer space={20} />
              <Stack alignItems="flex-start">
                <Typography fontWeight="600">Sort by price</Typography>
                <Stack direction="row" alignItems="center">
                  <Radio
                    checked={mainFilterInfo?.sortByPrice === "desc"}
                    onChange={(event) => {
                      mainFilterInfoDispatch({
                        filter: "sortByPrice",
                        value: event.target.value,
                      });
                    }}
                    value="desc"
                  />
                  <Typography>High To Low</Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Radio
                    checked={mainFilterInfo?.sortByPrice === "asc"}
                    onChange={(event) => {
                      mainFilterInfoDispatch({
                        filter: "sortByPrice",
                        value: event.target.value,
                      });
                    }}
                    value="asc"
                  />
                  <Typography>Low To High</Typography>
                </Stack>
              </Stack>
              <Spacer space={6} />
              <Stack alignItems="flex-start" spacing={1}>
                <Typography fontWeight="600">BHK</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  <Chip
                    label="One"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("1") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("1")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "1")
                          : [...mainFilterInfo.bhk, "1"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("1") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="Two"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("2") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("2")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "2")
                          : [...mainFilterInfo.bhk, "2"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("2") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="Three"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("3") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("3")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "3")
                          : [...mainFilterInfo.bhk, "3"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("3") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="Four"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("4") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("4")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "4")
                          : [...mainFilterInfo.bhk, "4"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("4") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                </Box>
              </Stack>
              <Spacer space={16} />
              <Stack alignItems="flex-start" spacing={1}>
                <Stack>
                  <Typography fontWeight="600">Locality</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: `${grey[700]}`,
                    }}
                  >
                    You can select multiple locations
                  </Typography>
                </Stack>
                <Select
                  displayEmpty
                  multiple
                  value={mainFilterInfo?.locality}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    mainFilterInfoDispatch({
                      filter: "locality",
                      value:
                        typeof value === "string" ? value.split(",") : value,
                    });
                    setIsLocalitySelectOpen(false);
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Typography fontStyle="italic" color={grey[600]}>
                          Available localities
                        </Typography>
                      );
                    }
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            sx={{
                              px: "5px",
                              bgcolor: `${primaryColor[100]}`,
                            }}
                          />
                        ))}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  open={isLocalitySelectOpen}
                  onOpen={() => setIsLocalitySelectOpen(true)}
                  onClose={() => setIsLocalitySelectOpen(false)}
                  sx={{
                    width: "100%",
                  }}
                >
                  {localities.map((locality) => (
                    <MenuItem
                      key={locality}
                      value={locality}
                      sx={{
                        // "&.MuiMenuItem-root:hover": {
                        //   backgroundColor: `${primaryColor[100]}`,
                        // },
                        "&.Mui-selected": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                      }}
                    >
                      {locality}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Spacer space={10} />
              <Stack alignItems="flex-start" spacing={1}>
                <Typography fontWeight="600">Select Range</Typography>
                <Box sx={{ width: "100%" }}>
                  <Slider
                    value={[
                      mainFilterInfo?.housesPriceRange[0] / 500,
                      mainFilterInfo?.housesPriceRange[1] / 500,
                    ]}
                    onChange={(event, newValue) => {
                      mainFilterInfoDispatch({
                        filter: "housesPriceRange",
                        value: [newValue[0] * 500, newValue[1] * 500],
                      });
                    }}
                    aria-labelledby="input-slider"
                    sx={{
                      height: 8,
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "#fff",
                        border: "2px solid currentColor",
                        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                          boxShadow: "inherit",
                        },
                        "&:before": {
                          display: "none",
                        },
                      },
                    }}
                  />
                </Box>
                <Spacer space={3} />
                <Stack direction="row" justifyContent="space-between" px="20px">
                  <TextField
                    label="Min Price"
                    value={mainFilterInfo?.housesPriceRange[0]}
                    disabled={true}
                    sx={{
                      width: "40%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        "-webkit-text-fill-color": "black",
                      },
                    }}
                  />
                  <TextField
                    label="Max Price"
                    value={mainFilterInfo?.housesPriceRange[1]}
                    disabled={true}
                    sx={{
                      width: "40%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        "-webkit-text-fill-color": "black",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Spacer space={30} />
            </Box>
            <Box
              sx={{
                width: "100%",
                // position: "fixed",
                // bottom: "25px",
                bgcolor: `${primaryBgColor}`,
              }}
            >
              <Center>
                <Button
                  disabled={
                    mainFilterInfo?.locality?.length +
                      mainFilterInfo?.bhk?.length >
                    10
                  }
                  sx={{ width: "90%", borderRadius: "7px" }}
                  variant="contained"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Center>
            </Box>
            <Spacer space={30} />
          </Box>
        </Box>
      </ActionSheet>

      <Box>
        <Center>
          <Chip
            label={
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="center"
              >
                <Typography sx={{ color: "black", fontWeight: "600" }}>
                  Filter by
                </Typography>
                <Typography>
                  <AnimatePresence mode="wait">
                    <motion.span
                      key={index}
                      initial={{ opacity: 0, y: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Typography
                        sx={{
                          color: `${primaryColor[800]}`,
                          fontWeight: "600",
                          width: "60px",
                        }}
                      >
                        {options[index]}
                      </Typography>
                    </motion.span>
                  </AnimatePresence>
                </Typography>
              </Stack>
            }
            icon={<Search sx={{ fontSize: "20px", color: "white" }} />}
            variant="outlined"
            sx={filterChipStyle}
            onClick={() => {
              ref.current.open();
              document.body.style.overflow = "hidden";
            }}
          />
        </Center>
      </Box>
    </>
  );
};

const filterChipStyle = {
  // bgcolor: `${primaryColor[800]}`,
  width: "100%",
  height: "35px",
  color: "white",
  "& .MuiChip-icon": {
    color: "black",
  },
  "& .MuiChip-label": {
    fontSize: "17px",
  },
  "&.MuiChip-clickable:hover": {
    // bgcolor: `${primaryColor[800]}`,
  },
};

export const FieldHeading = ({ heading }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Typography sx={{ fontWeight: "600" }}>{heading}</Typography>
      <Typography color={primaryColor[900]} sx={{ fontWeight: "500" }}>
        *
      </Typography>
    </Stack>
  );
};
