import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { primaryColor } from "../utils/color";

export const MySavedHeader = () => {
  return (
    <>
      <Box
        bgcolor={primaryColor[800]}
        sx={{ p: "5px", position: "sticky", top: "0", zIndex: "5" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton color="inherit" component={Link} to={"/"}>
            <ArrowBack sx={{ color: "white" }} />
          </IconButton>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="black"
              fontFamily="Ultra, serif"
            >
              My
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="white"
              fontFamily="Ultra, serif"
            >
              Saved
            </Typography>
          </Stack>
          <Box sx={{ width: "30px" }}></Box>
        </Stack>
      </Box>
    </>
  );
};
