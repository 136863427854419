import { Box, Button, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import Error from "../illustrations/error2.svg";
import { primaryColor } from "../utils/color";

export const ErrorParachuteComponent = () => {
  return (
    <>
      <Box>
        <Spacer space={30} />
        <Stack alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: "#fff59d",
              borderRadius: "50%",
              width: "220px",
              height: "140px",
              paddingLeft: "40px",
              paddingTop: "50px",
            }}
          >
            <img style={{ color: "red" }} height="320px" alt="" src={Error} />
          </Stack>
          <Spacer space={15} />
          <Stack alignItems="center">
            <Typography
              sx={{
                color: primaryColor[800],
                fontSize: "22px",
                fontWeight: "500",
                fontFamily: "Ultra, serif",
              }}
            >
              Something Went Wrong
            </Typography>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "500",
                fontFamily: "'Satisfy', cursive",
              }}
            >
              Please try again later
            </Typography>
          </Stack>
          <Spacer space={20} />
          <Button variant="contained" onClick={() => window.location.reload()}>
            Refresh
          </Button>
          <Spacer space={10} />
        </Stack>
      </Box>
    </>
  );
};
