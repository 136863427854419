import { Alert, Box, Typography } from "@mui/material";
import { blue, green, grey, red, yellow } from "@mui/material/colors";
import { Spacer } from "./Spacer";
import { PAYMENT_STATUS, POST_STATUS } from "../constants/constants";
import {
  EXPIRED_POST_STATUS_DESCRIPTION,
  FAILED_PAYMENT_STATUS_DESCRIPTION,
  INVALID_PAYMENT_STATUS_DESCRIPTION,
  IN_REVIEW_POST_STATUS_DESCRIPTION,
  REMOVED_POST_STATUS_DESCRIPTION,
} from "../dictionary/texts";

export const PostStatusBanner = ({ postStatus, paymentStatus }) => {
  const isPaymentError = paymentStatus !== PAYMENT_STATUS.SUCCESS;

  if (postStatus === POST_STATUS.ACTIVE) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ marginX: "12px" }}>
        <Alert
          severity={getSeverity(isPaymentError ? paymentStatus : postStatus)}
          sx={{
            border: `1px solid ${getBorderColor(
              isPaymentError ? paymentStatus : postStatus
            )}`,
            padding: "1px 12px",
            ...getCustomStyle(isPaymentError ? paymentStatus : postStatus),
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>
            {getBookmarkDescription(
              isPaymentError ? paymentStatus : postStatus
            )}
          </Typography>
        </Alert>
        <Spacer space={15} />
      </Box>
    </>
  );
};

const getSeverity = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return "success";
    case POST_STATUS.REMOVED:
      return "error";
    case POST_STATUS.EXPIRED:
      return "warning";
    case PAYMENT_STATUS.FAILED:
      return "error";
    case PAYMENT_STATUS.INVALID:
      return "error";
    default:
      return "";
  }
};

const getBorderColor = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return green[700];
    case POST_STATUS.REMOVED:
      return red[700];
    case POST_STATUS.EXPIRED:
      return grey[700];
    case PAYMENT_STATUS.FAILED:
      return red[700];
    case PAYMENT_STATUS.INVALID:
      return red[700];
    default:
      return "";
  }
};

const getBookmarkDescription = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return IN_REVIEW_POST_STATUS_DESCRIPTION;
    case POST_STATUS.REMOVED:
      return REMOVED_POST_STATUS_DESCRIPTION;
    case POST_STATUS.EXPIRED:
      return EXPIRED_POST_STATUS_DESCRIPTION;
    case PAYMENT_STATUS.FAILED:
      return FAILED_PAYMENT_STATUS_DESCRIPTION;
    case PAYMENT_STATUS.INVALID:
      return INVALID_PAYMENT_STATUS_DESCRIPTION;
    default:
      return "";
  }
};

const getCustomStyle = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return {};
    case POST_STATUS.REMOVED:
      return {};
    case POST_STATUS.EXPIRED:
      return {
        backgroundColor: grey[300],
        color: "black",
        "& .MuiAlert-icon": {
          color: "black",
        },
      };
    case PAYMENT_STATUS.FAILED:
      return {};
    case PAYMENT_STATUS.INVALID:
      return {};
    default:
      return {};
  }
};
