import Axios from "axios";
import { config } from "../config/config";

export const updatePaymentStatus = async (postUid) => {
  const { UPDATE_PAYMENT_STATUS } = config();

  await Axios.post(
    UPDATE_PAYMENT_STATUS,
    {
      postUid,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
