import { Box, Button, Dialog, Divider, Stack, Typography } from "@mui/material";
import { Center } from "../components/Center";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import { Phone } from "@mui/icons-material";
import { requestStatus } from "../constants/constants";

export const PostRequestInfoModal = ({
  modalInfo,
  setModalInfo,
  handlePostRequest,
}) => {
  const { requestInfo: modalRequestInfo } = modalInfo?.modalData || {};

  return (
    <>
      <Box>
        <Dialog
          open={modalInfo?.isOpen}
          onClose={() => {
            setModalInfo({
              isOpen: false,
              requestSatus: "",
              modalData: null,
            });
          }}
          fullWidth
        >
          <Box sx={{ padding: "20px", maxHeight: "60vh" }}>
            <Center>
              <Stack direction="row" justifyContent="flex-start" spacing={1}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                    fontFamily: "Calistoga, serif",
                  }}
                >
                  For
                </Typography>
                <Typography
                  color={primaryColor[900]}
                  sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                    fontFamily: "Calistoga, serif",
                  }}
                >
                  {modalRequestInfo?.requestFor}
                </Typography>
              </Stack>
            </Center>
            <Spacer space={10} />
            <Stack spacing={0.5}>
              <Stack>
                <Typography
                  sx={{ fontWeight: "700", color: primaryColor[800] }}
                >
                  Members
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "500" }}>
                    Adult: {modalRequestInfo?.memberCount?.adult}
                  </Typography>
                  <Typography sx={{ fontWeight: "500" }}>
                    Child: {modalRequestInfo?.memberCount?.child}
                  </Typography>
                </Stack>
              </Stack>
              <Spacer space={3} />
              <Divider color="white" />
              <Spacer space={3} />
              <Stack>
                <Typography
                  sx={{ fontWeight: "700", color: primaryColor[800] }}
                >
                  Name
                </Typography>
                <Typography sx={{ fontWeight: "500" }}>
                  {modalRequestInfo?.name}
                </Typography>
              </Stack>
              {modalRequestInfo?.requestFor === "Student" && (
                <>
                  <Stack>
                    <Typography
                      sx={{ fontWeight: "700", color: primaryColor[800] }}
                    >
                      Studying Place
                    </Typography>
                    <Typography sx={{ fontWeight: "500" }}>
                      {modalRequestInfo?.studyingPlace}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{ fontWeight: "700", color: primaryColor[800] }}
                    >
                      Studying Course
                    </Typography>
                    <Typography sx={{ fontWeight: "500" }}>
                      {modalRequestInfo?.studyingCource}
                    </Typography>
                  </Stack>
                </>
              )}
              {(modalRequestInfo?.requestFor === "Family" ||
                modalRequestInfo?.requestFor === "Bachelor") && (
                <Stack>
                  <Typography
                    sx={{ fontWeight: "700", color: primaryColor[800] }}
                  >
                    Profession
                  </Typography>
                  <Typography sx={{ fontWeight: "500" }}>
                    {modalRequestInfo?.profession}
                  </Typography>
                </Stack>
              )}
              <Stack>
                <Typography
                  sx={{ fontWeight: "700", color: primaryColor[800] }}
                >
                  Belongs to
                </Typography>
                <Typography sx={{ fontWeight: "500" }}>
                  {modalRequestInfo?.belongsTo}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{ fontWeight: "700", color: primaryColor[800] }}
                >
                  Message
                </Typography>
                <Typography sx={{ fontWeight: "500" }}>
                  {modalRequestInfo?.message}
                </Typography>
              </Stack>
            </Stack>
            <Spacer space={25} />
            {modalInfo?.requestSatus === requestStatus.pending && (
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    handlePostRequest(
                      modalInfo?.modalData?.requestUid,
                      "Rejected"
                    )
                  }
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    handlePostRequest(
                      modalInfo?.modalData?.requestUid,
                      "Approved"
                    )
                  }
                >
                  Approve
                </Button>
              </Stack>
            )}
            {modalInfo?.requestSatus === requestStatus.approved && (
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                startIcon={<Phone />}
                onClick={async () => {
                  window.open(`tel:${modalInfo?.userContactNumber}`);
                  setModalInfo({
                    isOpen: false,
                    requestSatus: "",
                    modalData: null,
                  });
                }}
              >
                Call Now
              </Button>
            )}
            {modalInfo?.requestSatus === requestStatus.rejected && (
              <Button
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={() =>
                  handlePostRequest(
                    modalInfo?.modalData?.requestUid,
                    "Approved"
                  )
                }
              >
                <Stack>
                  <Typography sx={{ fontSize: "12px" }}>
                    Changed Your Decision
                  </Typography>
                  <Typography>Approve</Typography>
                </Stack>
              </Button>
            )}
            <Spacer space={20} />
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
