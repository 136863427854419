import { Alert, Box } from "@mui/material";
import { Spacer } from "./Spacer";
import { Bookmark } from "@mui/icons-material";
import { requestStatus } from "../constants/constants";

export const RequestStatusBookmark = ({ status }) => {
  if (!status) {
    return <></>;
  }

  return (
    <>
      <Box>
        <Spacer space={15} />
        <Alert
          icon={<Bookmark fontSize="inherit" />}
          variant="filled"
          severity={requestStatusSeverityMap[status]}
          sx={{ width: "50%", borderRadius: "0px", paddingY: "0px" }}
        >
          Request {requestStatusMap[status]}
        </Alert>
      </Box>
    </>
  );
};

const requestStatusSeverityMap = {
  [requestStatus.approved]: "success",
  [requestStatus.pending]: "info",
  [requestStatus.rejected]: "error",
};

const requestStatusMap = {
  [requestStatus.approved]: "Approved",
  [requestStatus.pending]: "Pending",
  [requestStatus.rejected]: "Rejected",
};
