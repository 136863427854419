import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { PlanHeader } from "../components/plan-header";
import { Spacer } from "../components/Spacer";
import { primaryBgColor } from "../utils/color";
import { MainFooter } from "../components/MainFooter";
import ContactUsSVG from "../illustrations/contact-us.svg";

export const ContactUs = () => {
  return (
    <>
      <PlanHeader header1="Contact" header2="Us" />
      <Spacer space={18} />
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <Box sx={{ marginX: "8px" }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: "50%",
              width: "240px",
              height: "180px",
              paddingBottom: "16px",
            }}
          >
            <img height="420px" alt="" src={ContactUsSVG} />
          </Stack>
          <Spacer space={18} />
          <Typography sx={textStyle}>
            Your satisfaction is our priority !
          </Typography>
          <Typography sx={textStyle}>
            If you have any questions, feedback, or need support, please feel
            free to reach out to us through:
          </Typography>
          <Spacer space={14} />
          <Typography sx={subTitleStyle}>Customer Support</Typography>
          <Spacer space={3} />
          <Grid container spacing={0.5}>
            <Grid item xs={4}>
              <Typography>Email us at</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>-</Typography>
            </Grid>
            <Grid item xs={7}>
              <Link href="mailto:okhlahomes.care@gmail.com">
                @okhlahomes.care
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Typography>Operational address</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>-</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>
                Shaheen Bagh, Okhla, New Delhi, Delhi - 110025
              </Typography>
            </Grid>
          </Grid>
          <Spacer space={21} />
          <Typography sx={textStyle}>
            We look forward to hearing from you!
          </Typography>
          <Spacer space={96} />
        </Box>
        <MainFooter setFooterAtBottom={false} />
      </Box>
    </>
  );
};

const subTitleStyle = {
  fontSize: "17px",
  fontWeight: "600",
};

const textStyle = {
  fontSize: "17px",
};
