import { Box } from "@mui/material";
import { InstagramEmbed } from "react-social-media-embed";
import { ScreenBottomButton } from "./screen-bottom-button";
import { Spacer } from "./Spacer";
import { useEffect } from "react";

export const FullScreenInstaReel = ({ setIsInstaReelOpen, instaUrl }) => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopstate = (event) => {
      setIsInstaReelOpen(false);
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <Box>
        <InstagramEmbed url={instaUrl} captioned={true} />
        <Spacer space={80} />
        <ScreenBottomButton
          text="Close"
          action={() => setIsInstaReelOpen(false)}
        />
      </Box>
    </>
  );
};
