import { deletePendingPost } from "../helper/helper";
import { TitleTextActionActionModal } from "./title-text-action-action";

export const PendingDiscardModal = ({
  userUid,
  pendingPostInfo,
  setPendingPostInfo,
  postPropertyInfoDispatch,
  mediaDispatch,
}) => {
  const handleDiscardChanges = () => {
    deletePendingPost(userUid, pendingPostInfo);
    setPendingPostInfo({
      isPendingPostFetching: false,
      isPendingPostModalOpen: false,
      isPendingPost: false,
      pendingPostUid: null,
      pendingPostRef: null,
    });
    postPropertyInfoDispatch({
      type: "reset",
    });
    mediaDispatch({
      activity: "reset",
    });
  };

  const handleContinueChanges = () =>
    setPendingPostInfo({
      ...pendingPostInfo,
      isPendingPostModalOpen: false,
    });

  return (
    <>
      <TitleTextActionActionModal
        value="isPendingPostModalOpen"
        state={pendingPostInfo}
        setState={setPendingPostInfo}
        title="Pending Changes"
        text="You have unsaved changes. Would you like to discard them or continue editing ?"
        actionText1="Discard"
        actionFunction1={handleDiscardChanges}
        actionText2="Continue"
        actionFunction2={handleContinueChanges}
      />
    </>
  );
};
