import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import GooglePlaystoreIcon from "../icons/google-playstore.svg";
import SmileMobile from "../illustrations/smile-mobile.svg";
import { PLAYSTORE_URL } from "../constants/constants";

export const DesktopViewNotAvailableBanner = () => {
  const styleCheckFlag = useMediaQuery("(max-width:1051px)");

  return (
    <>
      <FullScreen>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "28px" }}
        >
          <Stack
            sx={{
              width: `${styleCheckFlag ? "80%" : "60%"}`,
            }}
          >
            <Stack
              direction={styleCheckFlag ? "column" : "row"}
              justifyContent="space-between"
              alignItems={styleCheckFlag ? "center" : "flex-end"}
            >
              <Box>
                <img width="220px" height="220px" alt="" src={SmileMobile} />
              </Box>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Box>
                  <img
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "10px",
                    }}
                    alt=""
                    src={require("../images/okhla-homes-logo.jpg")}
                  />
                </Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color={primaryColor[900]} sx={headingStyle}>
                    Okhla
                  </Typography>
                  <Typography color="black" sx={headingStyle}>
                    Homes
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Spacer space={40} />
            <Box
              sx={{
                border: `3px solid ${primaryColor[900]}`,
                borderRadius: "18px",
                padding: "18px",
              }}
            >
              <Typography>
                Our website is designed specifically for mobile devices to
                provide you with the best experience possible.
              </Typography>
              <Spacer space={20} />
              <Typography sx={{ ...textStyle }}>
                To access Okhla Homes, please visit our website on your mobile
                device, or if you want to access on a desktop, please resize
                your browser window to a smaller width by dragging its corner to
                mimic the dimensions of a mobile screen.
              </Typography>
              <Spacer space={20} />
              <Stack spacing={1}>
                <Typography>
                  You can also download our app for a seamless experience:
                </Typography>
                <Box onClick={() => window.open(PLAYSTORE_URL, "_self")}>
                  <img height="40px" alt="" src={GooglePlaystoreIcon} />
                </Box>
              </Stack>
              <Spacer space={30} />
              <Typography>
                We appreciate your understanding and look forward to serving you
                on a mobile devices.
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </FullScreen>
    </>
  );
};

const headingStyle = {
  fontSize: "36px",
  fontFamily: "Ultra, serif",
  fontWeight: "medium",
};

const textStyle = {};
