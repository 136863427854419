import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";

export const UseDcCallNow = (postData, isUserSignedIn, userContactNumber) => {
  const navigate = useNavigate();
  const [dcCallNowInfo, setDcCallNowInfo] = useState({
    isLoading: false,
    isError: false,
  });

  const handleDcCallNow = async () => {
    if (!isUserSignedIn || !userContactNumber) {
      navigate("/login", {
        state: { navigatedFrom: "/view-property", navigatedData: postData },
      });
      return;
    }

    setDcCallNowInfo({
      ...dcCallNowInfo,
      isLoading: true,
    });
    try {
      const docSnap = await getDoc(
        doc(firestoreDb, `posts/${postData?.postUid}/secure/contact-info`)
      );
      if (docSnap.exists()) {
        setDcCallNowInfo({
          isLoading: false,
          isError: false,
        });
        window.open(`tel:${docSnap.data()?.ownerContactNumber}`);
      } else {
        setDcCallNowInfo({
          isLoading: false,
          isError: false,
        });
      }
    } catch (err) {
      console.error("err while handling DC call now info - mylogs", err);
      setDcCallNowInfo({
        isLoading: false,
        isError: true,
      });
    }
  };

  return { dcCallNowInfo, setDcCallNowInfo, handleDcCallNow };
};
