import { Box } from "@mui/material";
import { PlanHeader } from "../components/plan-header";
import bodyContent from "../content/about-us.json";
import { primaryBgColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { MainFooter } from "../components/MainFooter";
import { ContentBody } from "../components/content-body";

export const AboutUs = () => {
  return (
    <>
      <PlanHeader header1="About" header2="Us" />
      <Spacer space={12} />
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <ContentBody bodyContent={bodyContent} />
        <Spacer space={16} />
        <MainFooter />
      </Box>
    </>
  );
};

// {
//   "title": "",
//   "description": [
//       {
//           "subTitle": "",
//           "text": "",
//           "listItems": []
//       }
//   ]
// }
