import {
  Box,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { requestStatus } from "../constants/constants";

export const PostRequestsListItem = ({
  i,
  request,
  setModalInfo,
  handleSeenRequest,
}) => {
  const handleOpenModal = () => {
    setModalInfo({
      isOpen: true,
      requestSatus: request?.requestStatus,
      modalData: request,
    });
    if (request?.requestStatus === requestStatus.pending && !request?.seen) {
      handleSeenRequest(request?.requestUid);
    }
  };

  return (
    <>
      <Box onClick={handleOpenModal}>
        <ListItem
          disablePadding
          sx={{
            textDecoration: "none",
            color: "black",
            fontFamily: "Neuton, serif",
          }}
        >
          <ListItemButton>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2.5}
              sx={{ width: "100%", bgcolor: "" }}
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontFamily: "Neuton, serif",
                  fontSize: "20px",
                  width: "100%",
                }}
              >
                {request?.requestInfo?.name}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignSelf="flex-end"
                sx={{ width: "100%" }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  {request?.time?.toDate().getDate()}{" "}
                  {request?.time?.toDate().toLocaleString("default", {
                    month: "short",
                  })}
                  , {request?.time?.toDate().getFullYear()}
                </Typography>
              </Stack>
            </Stack>
          </ListItemButton>
        </ListItem>
      </Box>
    </>
  );
};
