import { config } from "../config/config";
import { useState } from "react";
import Axios from "axios";

export const UseSendOtp = () => {
  const { SEND_OTP } = config();

  const [sendOtpInfo, setSendOtpInfo] = useState({
    isError: false,
    isLoading: false,
    sendOtpData: null,
  });

  const handleSendOtp = async (contactNumber) => {
    try {
      setSendOtpInfo({
        ...sendOtpInfo,
        isLoading: true,
      });
      // throw new Error("Test Error while sending otp");
      const { data } = await Axios.post(
        SEND_OTP,
        { phoneNumber: contactNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSendOtpInfo({
        ...sendOtpInfo,
        isLoading: false,
        sendOtpData: data?.data,
      });
    } catch (error) {
      console.log(
        "error while sending otp - use-send-otp.js - mylogs :",
        error
      );
      setSendOtpInfo({
        ...sendOtpInfo,
        isLoading: false,
        isError: true,
      });
    }
  };

  return { sendOtpInfo, setSendOtpInfo, handleSendOtp };
};
