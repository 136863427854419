import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";
import { furnishments } from "../constants/constants";
import { TagsInput } from "react-tag-input-component";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";

export const DetailsTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  return (
    <>
      <Box>
        <Stack spacing={1}>
          <FieldHeading heading="Your Name" />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            inputProps={{ maxLength: 24 }}
            placeholder="Your full name"
            sx={{
              ...textInputFieldStyle,
            }}
            value={postPropertyInfo?.fullName}
            onChange={(event) =>
              postPropertyInfoDispatch({
                filter: "fullName",
                value: event.target.value,
              })
            }
          />
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Rent" />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              Minimum rent 5,000 /-
            </Typography>
          </Stack>
          <TextField
            disabled={isEditPost}
            type="text"
            size="small"
            variant="outlined"
            placeholder="Rent per month"
            sx={{
              ...textInputFieldStyle,
              ...removingDigitsIndicatorsStyle,
            }}
            value={postPropertyInfo?.montlyRent?.toLocaleString("en-IN") || ""}
            onChange={(event) => {
              let value = event.target.value.replace(/,/g, "");
              if (value.length <= 5) {
                const numericValue = parseInt(value, 10);
                postPropertyInfoDispatch({
                  filter: "montlyRent",
                  value: isNaN(numericValue) ? "" : numericValue,
                });
              }
            }}
          />
        </Stack>
        <Spacer space={15} />
        <Stack spacing={1}>
          <FieldHeading heading="Security" />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            placeholder="Security to be deposited"
            sx={{
              ...textInputFieldStyle,
              ...removingDigitsIndicatorsStyle,
            }}
            value={postPropertyInfo?.security?.toLocaleString("en-IN") || ""}
            onChange={(event) => {
              let value = event.target.value.replace(/,/g, "");
              if (value.length <= 5) {
                const numericValue = parseInt(value, 10);
                postPropertyInfoDispatch({
                  filter: "security",
                  value: isNaN(numericValue) ? "" : numericValue,
                });
              }
            }}
          />
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <FieldHeading heading="BHK" />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="One"
              value="1"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Two"
              value="2"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Three"
              value="3"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Four"
              value="4"
            />
          </Box>
        </Stack>
        <Spacer space={20} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack spacing={1}>
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Parking
            </Typography>
            <Stack direction="row" spacing={2}>
              <ParkingChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Bike"
                value1="bike"
                value2="car"
              />
              <ParkingChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Car"
                value1="car"
                value2="bike"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Lift
            </Typography>
            <Switch
              checked={postPropertyInfo?.lift}
              onChange={() =>
                postPropertyInfoDispatch({
                  filter: "lift",
                  value: !postPropertyInfo?.lift,
                })
              }
            />
          </Stack>
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1} direction="row" alignItems="center">
          <FieldHeading heading="Furnishment" />
          <Select
            displayEmpty
            value={postPropertyInfo?.furnishment}
            onChange={(event) => {
              postPropertyInfoDispatch({
                filter: "furnishment",
                value: event.target.value,
              });
            }}
            input={<OutlinedInput />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 68 * 4.5 + 8,
                },
              },
            }}
            sx={{
              ...selectInputFieldStyle,
              width: "175px",
              height: "35px",
            }}
          >
            <MenuItem value="">
              <Typography fontStyle="italic" sx={{ color: grey[500] }}>
                Select
              </Typography>
            </MenuItem>
            {furnishments.map((furnishment) => (
              <MenuItem
                key={furnishment}
                value={furnishment}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: `${primaryColor[200]}`,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: `${primaryColor[200]}`,
                  },
                }}
              >
                {furnishment}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Spacer space={20} />
        {postPropertyInfo?.furnishment &&
          postPropertyInfo?.furnishment !== "Unfurnished" && (
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="flex-start" spacing={1}>
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Amenities
                </Typography>
                <Typography
                  color={primaryColor[900]}
                  sx={{ fontSize: "16px", fontWeight: "500" }}
                >
                  *
                </Typography>
              </Stack>
              <TagsInput
                value={postPropertyInfo?.amenities}
                onChange={(value) =>
                  postPropertyInfoDispatch({
                    filter: "amenities",
                    value: value,
                  })
                }
                separators={["Enter", " ", ","]}
                placeHolder="Write amenities"
              />
              <Typography
                color={grey[500]}
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingLeft: "10px",
                }}
              >
                Create amenities tags by pressing Enter (-&gt;)
              </Typography>
            </Stack>
          )}
        <Spacer space={30} />
      </Box>
    </>
  );
};

export const BhkChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.bhk === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "bhk",
          value: `${postPropertyInfo?.bhk === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.bhk === value ? "white" : grey[900]}`,
        backgroundColor: `${postPropertyInfo?.bhk === value ? "" : "white"}`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const ParkingChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value1,
  value2,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.parking?.[value1] ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "parking",
          value: {
            [value1]: !postPropertyInfo?.parking?.[value1],
            [value2]: postPropertyInfo?.parking?.[value2],
          },
        })
      }
      sx={{
        color: `${postPropertyInfo?.parking?.[value1] ? "white" : grey[900]}`,
        backgroundColor: `${
          postPropertyInfo?.parking?.[value1] ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
  },
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
