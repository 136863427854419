import { doc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { POST_STATUS } from "../constants/constants";

export const UsePostDisable = (postData, setIsDeletePostModalOpen) => {
  const navigate = useNavigate();
  const { triggerMyPostsRes, setTriggerMyPostsRes } =
    useContext(MyPostsContext);
  const [postDisableStatus, setpostDisableStatus] = useState({
    isLoading: false,
    isError: false,
    isDisabled: false,
  });

  const handleDisablePost = async () => {
    setIsDeletePostModalOpen(false);
    setpostDisableStatus({
      isLoading: true,
      isError: false,
      isDisabled: false,
    });

    const postUid = postData?.postUid;

    try {
      //   throw new Error("Error while disabling post");
      await updateDoc(doc(firestoreDb, `posts/${postUid}`), {
        postStatus: POST_STATUS.REMOVED,
      });

      setTriggerMyPostsRes(!triggerMyPostsRes);
      setpostDisableStatus({
        isLoading: false,
        isError: false,
        isDisabled: true,
      });
      console.log("Post is disabled successfully - mylogs");
      navigate("/my-posts");
    } catch (err) {
      console.error("err while disabling post - mylogs", err);
      setpostDisableStatus({
        isLoading: false,
        isError: true,
        isDisabled: false,
      });
    }
  };

  return { postDisableStatus, setpostDisableStatus, handleDisablePost };
};
