import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb, messaging } from "../firebase/firebaseConfig";
import { getToken } from "firebase/messaging";

export const UseMessagingInfo = (userUid) => {
  const [messagingInfo, setMessagingInfo] = useState({
    isLoading: true,
    isError: false,
    messagingInfoData: null,
  });
  const [messagingInfoModalStatus, setMessagingInfoModalStatus] = useState({
    isAskingPermissionModalOpen: false,
    isRequestPermissionModalOpen: false,
    isRequestPermissionSuccessModalOpen: false,
  });
  // console.log(
  //   "messagingInfoModalStatus - use-messaging-info.js - mylogs :",
  //   messagingInfoModalStatus
  // );
  const [triggerMessagingInfo, setTriggerMessagingInfo] = useState(false);

  const requestNotificationsPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("permission granted - Myposts.js - mylogs :", permission);
      await saveMessagingDeviceToken();
    } else {
      console.log("permission denied - Myposts.js - mylogs :", permission);
      setMessagingInfoModalStatus({
        ...messagingInfoModalStatus,
        isAskingPermissionModalOpen: false,
        isRequestPermissionModalOpen: true,
      });
    }
  };

  const saveMessagingDeviceToken = async () => {
    setMessagingInfoModalStatus({
      ...messagingInfoModalStatus,
      isAskingPermissionModalOpen: false,
    });
    try {
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BNCfPsaO9YcJg7ow9GtuJa_A4G8NFlUDJfD7E-D7a6PtcpAqgCC3Tu5eGJgVcOXGItZh7STj0WN2h4cT33VKQ_M",
      });
      if (fcmToken) {
        console.log("fcmToken - Myposts.js - mylogs :", fcmToken);
        try {
          await setDoc(
            doc(firestoreDb, `users/${userUid}/messaging/messaging-info`),
            {
              fcmToken: fcmToken,
            }
          );
          setTriggerMessagingInfo(!triggerMessagingInfo);
          setMessagingInfoModalStatus({
            ...messagingInfoModalStatus,
            isAskingPermissionModalOpen: false,
            isRequestPermissionSuccessModalOpen: true,
          });
        } catch (err) {
          console.error(
            "err while adding fcmToken - Myposts.js - mylogs :",
            err
          );
        }
      } else {
        requestNotificationsPermission();
      }
    } catch (err) {
      console.error("err while getting fcmToken - Myposts.js - mylogs :", err);
      requestNotificationsPermission();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const docSnap = await getDoc(
          doc(firestoreDb, `users/${userUid}/messaging/messaging-info`)
        );
        if (docSnap.exists()) {
          setMessagingInfo({
            isLoading: false,
            isError: false,
            messagingInfoData: docSnap.data(),
          });
        } else {
          setMessagingInfo({
            isLoading: false,
            isError: false,
            messagingInfoData: null,
          });
        }
      } catch (err) {
        console.log("err - Myposts.js - mylogs :", err);
        setMessagingInfo({
          isLoading: false,
          isError: true,
          messagingInfoData: null,
        });
      }
    })();
  }, [triggerMessagingInfo]);

  return {
    messagingInfo,
    messagingInfoModalStatus,
    setMessagingInfoModalStatus,
    saveMessagingDeviceToken,
  };
};
