import Axios from "axios";
import { config } from "../config/config";
import { getPostAmountInPaisa } from "../utils/getPostAmount";
import { POST_CHARGE_PERCENTAGE } from "../constants/constants";

export const createPaymentOrder = async (postUid, couponCode) => {
  const { CREATE_PAYMENT_ORDER } = config();

  const { data } = await Axios.post(
    CREATE_PAYMENT_ORDER,
    {
      postUid,
      couponCode,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
