import { ArrowBack, Notifications } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { isApp } from "../utils/isApp";
import { primaryColor } from "../utils/color";

export const MyPostsHeader = ({
  messagingInfo,
  messagingInfoModalStatus,
  setMessagingInfoModalStatus,
}) => {
  return (
    <>
      <Box
        bgcolor={primaryColor[800]}
        sx={{ p: "5px", position: "sticky", top: "0", zIndex: "5" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton color="inherit" component={Link} to={"/"}>
            <ArrowBack sx={{ color: "white" }} />
          </IconButton>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="black"
              fontFamily="Ultra, serif"
            >
              My
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="white"
              fontFamily="Ultra, serif"
            >
              Posts
            </Typography>
          </Stack>
          {isApp() && !messagingInfo?.isLoading ? (
            <Box>
              {messagingInfo?.messagingInfoData?.fcmToken ? (
                <Box sx={{ width: "50px" }}></Box>
              ) : (
                <IconButton
                  color="inherit"
                  onClick={() =>
                    setMessagingInfoModalStatus({
                      ...messagingInfoModalStatus,
                      isAskingPermissionModalOpen: true,
                    })
                  }
                >
                  <Notifications sx={{ color: "white" }} />
                </IconButton>
              )}
            </Box>
          ) : (
            <Box sx={{ width: "50px" }}></Box>
          )}
        </Stack>
      </Box>
    </>
  );
};
