import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import { getPostAmountInPaisa } from "../utils/getPostAmount";
import { FullScreen } from "../components/FullScreen";
import LoadingButton from "@mui/lab/LoadingButton";
import { UseVerifyPromoCode } from "../hooks/use-verify-promo-code";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import {
  POST_CHARGE_PERCENTAGE,
  POST_DURATION_MONTHS,
} from "../constants/constants";

export const PostAmountModal = ({
  value,
  state,
  setState,
  montlyRent,
  handlePay,
  handleFreePost,
}) => {
  const { postAmountModalInfo, setPostAmountModalInfo, handlePromoCode } =
    UseVerifyPromoCode();
  const isFreePost =
    postAmountModalInfo?.promoCodeStatus === "VALID" &&
    postAmountModalInfo?.postChargePercentage === "0";

  return (
    <>
      <FullScreen bgcolor="white"></FullScreen>
      <Dialog
        open={state[value]}
        onClose={() =>
          setState({
            ...state,
            [value]: false,
          })
        }
        fullWidth
      >
        <Box
          sx={{
            padding: "14px",
          }}
        >
          <Stack alignItems="center" justifyContent="center">
            <Typography
              sx={{
                color: green[800],
                fontWeight: "300",
                fontSize: "20px",
                fontFamily: "Calistoga, serif",
                textShadow: "1px 1px 4px orange",
              }}
            >
              Post Your Property Free
            </Typography>
            <Spacer space={4} />
            <Typography
              sx={{
                color: primaryColor[800],
                fontWeight: "600",
                fontSize: "18px",
                fontFamily: "'Satisfy', cursive",
              }}
            >
              At Returnable{" "}
              {postAmountModalInfo?.postChargePercentage ||
                POST_CHARGE_PERCENTAGE}
              % of Rent Amount
            </Typography>
            <Spacer space={15} />
            <Stack>
              <Typography
                sx={{
                  color: primaryColor[800],
                  fontWeight: "600",
                  fontSize: "15px",
                  fontFamily: "'Roboto Serif', serif",
                  alignSelf: "center",
                }}
              >
                How it works
              </Typography>
              <List disablePadding={true} dense={true}>
                <ListItem>
                  <Typography
                    sx={{
                      // color: primaryColor[800],
                      fontWeight: "400",
                      fontFamily: "'Roboto Serif', serif",
                      fontSize: "14px",
                      lineHeight: "18p",
                      textAlign: "center",
                    }}
                  >
                    List your property at{" "}
                    {postAmountModalInfo?.postChargePercentage ||
                      POST_CHARGE_PERCENTAGE}
                    % of rent amount for {POST_DURATION_MONTHS} months.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'Roboto Serif', serif",
                      textAlign: "center",
                    }}
                  >
                    Once any tenant agrees to lease your property, seamlessly
                    include the{" "}
                    {postAmountModalInfo?.postChargePercentage ||
                      POST_CHARGE_PERCENTAGE}
                    % property listing charge alongside the rent amount.
                  </Typography>
                </ListItem>
              </List>
            </Stack>
            {postAmountModalInfo?.isCouponCode && (
              <>
                <Spacer space={20} />
                <Stack spacing={0.5}>
                  <TextField
                    type="text"
                    size="small"
                    variant="outlined"
                    placeholder="Promo code"
                    sx={{
                      ...textInputFieldStyle,
                    }}
                    value={postAmountModalInfo?.couponCode}
                    disabled={postAmountModalInfo?.promoCodeStatus === "VALID"}
                    onChange={(event) =>
                      setPostAmountModalInfo({
                        ...postAmountModalInfo,
                        couponCode: event.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LoadingButton
                            size="small"
                            onClick={handlePromoCode}
                            loading={postAmountModalInfo?.isLoading}
                            disabled={
                              postAmountModalInfo?.promoCodeStatus === "VALID"
                            }
                            variant="text"
                            sx={{
                              color: green[800],
                            }}
                          >
                            <span>Apply</span>
                          </LoadingButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {postAmountModalInfo?.isError && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      alignSelf="flex-start"
                      spacing={0.5}
                    >
                      <ErrorOutline
                        sx={{ fontSize: "16px", color: red[800] }}
                      />
                      <Typography
                        sx={{
                          color: red[800],
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Oop, Something went wrong !!!
                      </Typography>
                    </Stack>
                  )}
                  {postAmountModalInfo?.promoCodeStatus === "IN_VALID" && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      alignSelf="flex-start"
                      spacing={0.5}
                    >
                      <ErrorOutline
                        sx={{ fontSize: "16px", color: red[800] }}
                      />
                      <Typography
                        sx={{
                          color: red[800],
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        invalid/expired promo code !!!
                      </Typography>
                    </Stack>
                  )}
                  {postAmountModalInfo?.promoCodeStatus === "VALID" && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      alignSelf="flex-start"
                      spacing={0.5}
                    >
                      <CheckCircleOutline
                        sx={{ fontSize: "16px", color: green[800] }}
                      />
                      <Typography
                        sx={{
                          color: green[800],
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Promo Code Applied Successfully !!!
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </>
            )}
            <Spacer space={20} />
            <Box
              sx={{
                width: "80%",
                border: "2px solid #4CAF50",
                backgroundColor: "#E8F5E9",
                borderRadius: "5px",
                paddingY: "4px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: `${green[900]}`,
                  }}
                >
                  Amount : Rs{" "}
                  {Math.floor(
                    getPostAmountInPaisa(
                      montlyRent,
                      postAmountModalInfo?.promoCodeStatus,
                      postAmountModalInfo?.postChargePercentage
                    ) / 100
                  ).toLocaleString("en-IN")}{" "}
                  /-
                </Typography>
              </Stack>
            </Box>
            <Spacer space={8} />
            <Stack direction="row" alignSelf="flex-start" spacing={2}>
              {/* A-logic++ : couponCode need to be empty when Promo code unchecked*/}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={postAmountModalInfo?.isCouponCode}
                    onChange={() =>
                      setPostAmountModalInfo({
                        isLoading: false,
                        isError: false,
                        isCouponCode: !postAmountModalInfo?.isCouponCode,
                        couponCode: "",
                        postChargePercentage: null,
                        promoCodeStatus: "",
                      })
                    }
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                  />
                }
                label="Promo Code"
              />
            </Stack>
            <Spacer space={8} />
            <Button
              disabled={
                postAmountModalInfo?.isCouponCode
                  ? postAmountModalInfo?.promoCodeStatus !== "VALID"
                  : false
              }
              variant="contained"
              sx={{ width: "100%" }}
              onClick={() => {
                setState({
                  ...state,
                  [value]: false,
                });
                if (isFreePost) {
                  handleFreePost();
                  return;
                }
                handlePay(postAmountModalInfo?.couponCode);
              }}
            >
              {isFreePost ? "Post" : " Pay & Post"}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: green[400],
      borderWidth: "2px",
    },
  },
};
