import { useContext, useState } from "react";
import Axios from "axios";
import { config } from "../config/config";
import { ProfileContext } from "../context/ProfileContextProvider";

export const UseSubmitOtp = (userUid, verificationId, otp, contactNumber) => {
  const { VALIDATE_OTP } = config();
  const { triggerProfileContextRes, setTriggerProfileContextRes } =
    useContext(ProfileContext);

  const [submitOtpInfo, setSubmitOtpInfo] = useState({
    isError: false,
    isLoading: false,
    submitOtpStatus: null,
  });

  const handleSubmitOtp = async () => {
    try {
      setSubmitOtpInfo({
        ...submitOtpInfo,
        isLoading: true,
      });
      // throw new Error("Test Error while submiting otp");
      const { data } = await Axios.post(
        VALIDATE_OTP,
        { otp, phoneNumber: contactNumber, userUid, verificationId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSubmitOtpInfo({
        ...submitOtpInfo,
        isLoading: false,
        submitOtpStatus: data?.status,
      });

      if (data?.status === "success") {
        setTriggerProfileContextRes(!triggerProfileContextRes);
      } else {
        console.log("Invalid OTP - mobie-verification.js - mylogs");
      }
    } catch (error) {
      console.log(
        "error while submitting otp - use-submit-otp.js - mylogs :",
        error
      );
      setSubmitOtpInfo({
        ...submitOtpInfo,
        isLoading: false,
        isError: true,
      });
    }
  };

  return { submitOtpInfo, setSubmitOtpInfo, handleSubmitOtp };
};
