import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { primaryColor } from "../utils/color";

export const LoginTagLine = () => {
  const tagLine = "Helping, To find a better place for you";

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  return (
    <>
      <Stack alignItems="center">
        <Typography
          fontFamily="Caveat, cursive"
          fontSize="25px"
          fontWeight="100"
          color={primaryColor[700]}
        >
          <motion.span variants={sentence} initial="hidden" animate="visible">
            {tagLine.split("").map((char, index) => {
              return (
                <motion.span key={char + "-" + index} variants={letter}>
                  {char}
                </motion.span>
              );
            })}
          </motion.span>
        </Typography>
      </Stack>
    </>
  );
};
