import { Button, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Link } from "react-router-dom";
import { primaryColor } from "../utils/color";
import Error from "../illustrations/error2.svg";

export const SomethingWentWrongScreen = () => {
  return (
    <>
      <FullScreen spacing={4}>
        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontFamily: "Calistoga, serif",
              fontSize: "42px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Something Went Wrong !!
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Please try again later sometime
          </Typography>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: "50%",
            width: "250px",
            height: "150px",
            paddingLeft: "70px",
            paddingTop: "50px",
          }}
        >
          <img width="470px" height="470px" alt="" src={Error} />
        </Stack>
        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
        >
          Go to Home page
        </Button>
      </FullScreen>
    </>
  );
};
