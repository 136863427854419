import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { Center } from "../components/Center";
import { useEffect, useState } from "react";

export const CongratulationModal = ({ value, state, setState, text }) => {
  return (
    <>
      <Box>
        <Dialog
          open={state[value]}
          onClose={() =>
            setState({
              ...state,
              [value]: false,
            })
          }
          fullWidth
        >
          <Box sx={{ padding: "20px" }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="center"
            >
              <Typography
                color={primaryColor[800]}
                sx={{
                  fontFamily: "'Satisfy', cursive",
                  fontSize: "28px",
                  fontWeight: "600",
                }}
              >
                Congratulation
              </Typography>
            </Stack>
            <Spacer space={15} />
            <Typography sx={{ fontSize: "15px" }}>{text}</Typography>
            <Typography sx={{ fontSize: "15px" }}></Typography>
            <Spacer space={20} />
            <Center>
              <Button
                variant="contained"
                sx={{ width: "95%", height: "30px" }}
                onClick={() => {
                  setState({
                    ...state,
                    [value]: false,
                  });
                }}
              >
                Okay
              </Button>
            </Center>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
