import { Backdrop, Box, CircularProgress } from "@mui/material";

export const BackdropLoader = ({ isLoading }) => {
  return (
    <>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};
