import FLAGS from "../flags/flags";
import { isDevMode } from "../utils/is-dev-mode";

const configs = {
  localhost: {
    UPDATE_PAYMENT_STATUS:
      "http://127.0.0.1:5001/okhla-homes/us-central1/updatePaymentStatus",
    VALIDATE_PAYMENT_SIGNATURE:
      "http://127.0.0.1:5001/okhla-homes/us-central1/validatePaymentSignature",
    CREATE_PAYMENT_ORDER: "http://127.0.0.1:5001/okhla-homes/us-central1/createPaymentOrder",
    PAYMENT_INFO:
      "http://127.0.0.1:5001/okhla-homes/us-central1/getPaymentInfo",
    SEND_OTP: "http://127.0.0.1:5001/okhla-homes/us-central1/sendOtp",
    VALIDATE_OTP: "http://127.0.0.1:5001/okhla-homes/us-central1/validateOtp",
    VERIFY_PROMO_CODE:
      "http://127.0.0.1:5001/okhla-homes/us-central1/verifyPromoCode",
  },
  production: {
    UPDATE_PAYMENT_STATUS:
      "https://us-central1-okhla-homes.cloudfunctions.net/updatePaymentStatus",
    VALIDATE_PAYMENT_SIGNATURE:
      "https://us-central1-okhla-homes.cloudfunctions.net/validatePaymentSignature",
    CREATE_PAYMENT_ORDER:
      "https://us-central1-okhla-homes.cloudfunctions.net/createPaymentOrder",
    PAYMENT_INFO:
      "https://us-central1-okhla-homes.cloudfunctions.net/getPaymentInfo",
    SEND_OTP: "https://us-central1-okhla-homes.cloudfunctions.net/sendOtp",
    VALIDATE_OTP:
      "https://us-central1-okhla-homes.cloudfunctions.net/validateOtp",
    VERIFY_PROMO_CODE:
      "https://us-central1-okhla-homes.cloudfunctions.net/verifyPromoCode",
  },
};

export const config = () => {
  if (isDevMode() && FLAGS.DEV_MODE) {
    return { ...configs.localhost };
  } else {
    return { ...configs.production };
  }
};
