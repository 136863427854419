import { Box, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { PAYMENT_STATUS, POST_STATUS } from "../constants/constants";

export const PostStatusBookmark = ({ postStatus, paymentStatus }) => {
  const isPaymentError = paymentStatus !== PAYMENT_STATUS.SUCCESS;

  if (postStatus === POST_STATUS.ACTIVE) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: isPaymentError ? 185 : 150,
          left: -24,
          zIndex: 98,
          backgroundColor: getBookmarkBackgroundColor(
            isPaymentError ? paymentStatus : postStatus
          ),
          color: "white",
          width: isPaymentError ? "230px" : "180px",
          textAlign: "center",
          transform: "rotate(-45deg)",
          transformOrigin: "top left",
          padding: "2px 0",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>
          {getBookmarkTitle(isPaymentError ? paymentStatus : postStatus)}
        </Typography>
      </Box>
    </>
  );
};

const getBookmarkTitle = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return "In Review";
    case POST_STATUS.REMOVED:
      return "Removed";
    case POST_STATUS.EXPIRED:
      return "Expired";
    case PAYMENT_STATUS.FAILED:
      return "Payment Failed";
    case PAYMENT_STATUS.INVALID:
      return "Payment Invalid";
    default:
      return "";
  }
};

const getBookmarkBackgroundColor = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return green[700];
    case POST_STATUS.REMOVED:
      return red[700];
    case POST_STATUS.EXPIRED:
      return grey[700];
    case PAYMENT_STATUS.FAILED:
      return red[700];
    case PAYMENT_STATUS.INVALID:
      return red[700];
    default:
      return "";
  }
};
