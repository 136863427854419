import Axios from "axios";
import { config } from "../config/config";

export const verifyPromoCode = async (promoCode) => {
  const { VERIFY_PROMO_CODE } = config();

  const { data } = await Axios.post(
    VERIFY_PROMO_CODE,
    {
      promoCode,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
