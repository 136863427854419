export const localities = [
  "Abul Fazal Enclave",
  "Ali Village",
  "Batla House",
  "Canal Colony",
  "Gaffar Manzil",
  "Ghafoor Nagar",
  "Hazi Colony",
  "Jamia Nagar",
  "Jasola Extension",
  "Jasola Vihar",
  "Jasola Village",
  "Jogabai",
  "Kalinidi Colony",
  "Khizarbad Village",
  "Madanpur Khadar",
  "Mashigarh Village",
  "Mujeeb Bagh",
  "Nai Basti",
  "New Friends Colony",
  "Noor Nagar",
  "Okhla Vihar",
  "Okhla Village",
  "Sarita Vihar",
  "Shaheen Bagh",
  "Taimoor Nagar",
  "Zakir Nagar",
].sort();

export const requestStatus = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const PAYMENT_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
  INVALID: "invalid",
};

export const POST_STATUS = {
  ACTIVE: "active",
  REMOVED: "removed",
  IN_REVIEW: "in-review",
  EXPIRED: "expired",
};

export const floors = [
  "Ground",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
];

export const isRequiredMedia = {
  hall: true,
  bedrooms: true,
  kitchen: true,
  bathroom: true,
  front: false,
};

export const mediaLimits = {
  hall: 3,
  bedrooms: 6,
  kitchen: 2,
  bathroom: 2,
  front: 2,
};

export const mediaSorted = ["hall", "bedrooms", "kitchen", "bathroom", "front"];

export const furnishments = ["Furnished", "Semi Furnished", "Unfurnished"];

export const POST_CHARGE_PERCENTAGE = 9;

export const POST_DURATION_MONTHS = "one";

export const LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/okhla-homes.appspot.com/o/okhla-homes-logo.jpg?alt=media&token=a992f7ad-46c6-4d47-8010-1b5047af20b6";

export const PLAYSTORE_URL =
  "https://play.google.com/store/apps/details?id=com.okhlahomes.twa&hl=en_US";

export const RAZORPAY_KEY_ID_DEV = "rzp_test_iBUbFum4USwfMw";

export const RAZORPAY_KEY_ID_PROD = "rzp_live_jI3EBspdE6LjEm";

export const statesAndUTs = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];
