import { Box, Button } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Link } from "react-router-dom";
import PostIcon from "../illustrations/homeSearch.svg";

export const ExplorePropertyScreen = () => {
  return (
    <>
      <Box>
        <FullScreen spacing={6}>
          <Box></Box>
          <Box>
            <img width="300px" height="300px" alt="" src={PostIcon} />
          </Box>
          <Button
            variant="contained"
            sx={{ width: "90%" }}
            component={Link}
            to="/"
          >
            Explore Properties
          </Button>
        </FullScreen>
      </Box>
    </>
  );
};
