import { Box, Button, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Link } from "react-router-dom";
import { primaryColor } from "../utils/color";
import EmptyFilterResultIllustration from "../illustrations/emptyFilterResult.svg";

export const NoPropertyFound = () => {
  return (
    <>
      <FullScreen spacing={2}>
        <Stack alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              sx={{
                color: primaryColor[800],
                fontSize: "34px",
                fontWeight: "500",
                fontFamily: "Ultra, serif",
                textAlign: "center",
              }}
            >
              Oops! No
            </Typography>
          </Stack>
          <Typography
            sx={{
              color: primaryColor[800],
              fontSize: "34px",
              fontWeight: "500",
              fontFamily: "Ultra, serif",
              textAlign: "center",
            }}
          >
            Property Found
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "22px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Let's explore other properties
          </Typography>
        </Stack>
        <Box>
          <img
            width="280px"
            height="280px"
            alt=""
            src={EmptyFilterResultIllustration}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
        >
          Go to Home page
        </Button>
      </FullScreen>
    </>
  );
};
