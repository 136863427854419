import { Box, Button } from "@mui/material";
import { FullScreen } from "./FullScreen";
import PostIcon from "../illustrations/post.svg";
import { Link } from "react-router-dom";

export const ListPropertyScreen = ({ isUserSignedIn }) => {
  return (
    <>
      <Box>
        <FullScreen spacing={6}>
          <Box></Box>
          <Box>
            <img width="260px" height="260px" alt="" src={PostIcon} />
          </Box>
          <Button
            variant="contained"
            sx={{ width: "90%" }}
            component={Link}
            to={isUserSignedIn ? "/list-property" : "/login"}
            state={{
              navigatedFrom: "list-your-property",
            }}
          >
            List Your Property
          </Button>
        </FullScreen>
      </Box>
    </>
  );
};
