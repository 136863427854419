import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { POST_STATUS, mediaSorted } from "../constants/constants";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import { convertFirebaseTimestamp } from "../utils/generals";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { useState } from "react";
import { ContentCopy, Info } from "@mui/icons-material";
import { TextOkayModal } from "../modals/text-okay";
import {
  SERVICE_CHARGE_FREE_DESCRIPTION,
  SERVICE_CHARGE_PERCENTAGE_DESCRIPTION,
} from "../dictionary/texts";
import { PostStatusBanner } from "./post-status-banner";

export const ViewPropertyBody = ({
  postData,
  setMediaIndexCount,
  setIsSliderOpen,
  setIsInstaReelOpen,
}) => {
  const { postInfo, postUid } = postData || {};
  const [serviceChargeInfoModal, setServiceChargeInfoModal] = useState({
    isServiceChargeInfoOpen: false,
  });
  const postTime = convertFirebaseTimestamp(postData?.postTime);
  const availableOn = convertFirebaseTimestamp(postInfo?.availableFrom);
  const isFreePost = postData?.postChargePercentage === "0";
  const isPostActive = postData?.postStatus === POST_STATUS.ACTIVE;

  return (
    <>
      <TextOkayModal
        value="isServiceChargeInfoOpen"
        state={serviceChargeInfoModal}
        setState={setServiceChargeInfoModal}
        text={
          isFreePost
            ? SERVICE_CHARGE_FREE_DESCRIPTION
            : SERVICE_CHARGE_PERCENTAGE_DESCRIPTION(
                postData?.postChargePercentage
              )
        }
      />
      <Spacer space={20} />
      <Box>
        <Box sx={{ m: "0px 20px" }}>
          <Carousel
            autoPlay={false}
            infiniteLoop={true}
            transitionTime={250}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeScrollTolerance={30}
            preventMovementUntilSwipeScrollTolerance={true}
            onChange={(index) => setMediaIndexCount(index + 1)}
          >
            {mediaSorted?.map((category, i) => {
              return postData?.media[category]?.map((property, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => {
                      setIsSliderOpen(true);
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        objectFit: "fill",
                        borderRadius: "5px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                      src={property}
                      alt=""
                    />
                    <Box
                      className="category"
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "12px",
                        backgroundColor: "black",
                        color: "white",
                        paddingX: "3px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                );
              });
            })}
          </Carousel>
        </Box>
        <Box sx={{ opacity: isPostActive ? "1" : "0.5" }}>
          {postData?.videosMedia?.instaUrl ? (
            <Stack direction="row" justifyContent="center">
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => setIsInstaReelOpen(true)}
              >
                Watch Video
              </Button>
            </Stack>
          ) : (
            <Spacer space={20} />
          )}
        </Box>
        <PostStatusBanner
          postStatus={postData?.postStatus}
          paymentStatus={postData?.paymentStatus}
        />
        <Box sx={{ opacity: isPostActive ? "1" : "0.5" }}>
          <Box sx={{ px: "20px" }}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                  }}
                >
                  Rs: {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")}{" "}
                  / Month
                </Typography>
                <Typography>
                  {postTime.getDate()}{" "}
                  {postTime.toLocaleString("default", {
                    month: "short",
                  })}
                  , {postTime.getFullYear()}
                </Typography>
              </Stack>
              <Stack spacing={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    color={primaryColor[900]}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Calistoga, serif",
                    }}
                  >
                    {postInfo?.address?.locality}
                  </Typography>
                  <Typography sx={{ color: primaryColor[900] }}>
                    {postInfo?.bhk} Bhk
                  </Typography>
                </Stack>
                <Typography sx={{ fontSize: "14px" }}>
                  {postInfo?.fullName}: {postInfo?.address?.block} - block,{" "}
                  {postInfo?.address?.floor} floor
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Near By : {postInfo?.address?.nearBy}
                </Typography>
                <Spacer space={10} />
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: "14px" }}>
                    Service charge - {postData?.postChargePercentage}%
                  </Typography>
                  <IconButton
                    onClick={() =>
                      setServiceChargeInfoModal({
                        isServiceChargeInfoOpen: true,
                      })
                    }
                  >
                    <Info
                      sx={{ fontSize: "16px", color: `${primaryColor[800]}` }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Spacer space={15} />
          <Box sx={{ px: "25px" }}>
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Calistoga, serif",
                }}
              >
                Preference
              </Typography>
              <Typography
                color={primaryColor[900]}
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Calistoga, serif",
                }}
              >
                For
              </Typography>
            </Stack>
            <Spacer space={12} />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {postInfo?.preference?.map((value) => (
                <Chip
                  key={value}
                  label={value.charAt(0).toUpperCase() + value.slice(1)}
                  sx={{
                    p: "1px",
                    bgcolor: `${primaryColor[100]}`,
                  }}
                />
              ))}
            </Box>
            <Spacer space={20} />
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Calistoga, serif",
                }}
              >
                Property
              </Typography>
              <Typography
                color={primaryColor[900]}
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Calistoga, serif",
                }}
              >
                Details
              </Typography>
            </Stack>
            <Spacer space={3} />
            <Stack direction="row" alignItems="center">
              <Typography>Post Id - {postData?.postUid}</Typography>
              {/* <IconButton
              onClick={() => navigator.clipboard.writeText(postData?.postUid)}
            >
              <ContentCopy
                sx={{ fontSize: "16px", color: `${primaryColor[800]}` }}
              />
            </IconButton> */}
            </Stack>
            <Spacer space={6} />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography>Available from</Typography>
              </Grid>
              <Grid item xs={6}>
                {availableOn.getDate()}{" "}
                {availableOn.toLocaleString("default", {
                  month: "short",
                })}
                , {availableOn.getFullYear()}
              </Grid>
              <Grid item xs={6}>
                <Typography>Area</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.area} {" Gajj"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Security</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {Math.floor(postInfo?.security).toLocaleString("en-IN")}
                  {" /-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Furnishment</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{postInfo?.furnishment}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Lift</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{postInfo?.lift ? "Available" : "No"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Bike Parking</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.parking?.bike ? "Available" : "No"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Car Parking</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.parking?.car ? "Available" : "No"}
                </Typography>
              </Grid>
            </Grid>
            {postInfo?.furnishment !== "Unfurnished" && (
              <>
                <Spacer space={15} />
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Calistoga, serif",
                    }}
                  >
                    House
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Calistoga, serif",
                    }}
                  >
                    Amenities
                  </Typography>
                </Stack>
                <Spacer space={12} />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {postInfo?.amenities?.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      sx={{
                        p: "1px",
                        bgcolor: `${primaryColor[100]}`,
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
