import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firestoreDb, googleAuth } from "../firebase/firebaseConfig";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import * as Sentry from "@sentry/react";

export const ProfileContext = createContext();

const ProfileContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    isUserSignedIn: false,
    isProfileContextLoading: true,
    isProfileContextError: false,
    userInfo: {},
    userData: {},
  });
  const [triggerProfileContextRes, setTriggerProfileContextRes] =
    useState(false);

  useEffect(() => {
    onAuthStateChanged(googleAuth, async (user) => {
      if (user) {
        setUser({
          isUserSignedIn: true,
          isProfileContextLoading: true,
          isProfileContextError: false,
          userInfo: {},
          userData: {},
        });

        const { uid, displayName, email, phoneNumber, photoURL } = user;
        Sentry.setUser({ id: uid });

        try {
          const docSnap = await getDoc(doc(firestoreDb, "users", uid));
          if (docSnap.exists()) {
            setUser({
              isUserSignedIn: true,
              isProfileContextLoading: false,
              isProfileContextError: false,
              userInfo: {
                uid,
                displayName,
                email,
                phoneNumber,
                photoURL,
              },
              userData: docSnap.data(),
            });
          } else {
            console.log("No user data found - mylogs");
            setUser({
              isUserSignedIn: true,
              isProfileContextLoading: false,
              isProfileContextError: false,
              userInfo: {
                uid,
                displayName,
                email,
                phoneNumber,
                photoURL,
              },
              userData: {},
            });
          }
        } catch (err) {
          console.error(
            "err while fetching userData - ProfileContextProvider.js - mylogs :",
            err
          );
          setUser({
            isUserSignedIn: true,
            isProfileContextLoading: false,
            isProfileContextError: false,
            userInfo: {
              uid,
              displayName,
              email,
              phoneNumber,
              photoURL,
            },
            userData: {},
          });
        }
      } else {
        console.warn("User signed out - mylogs");
        setUser({
          isUserSignedIn: false,
          isProfileContextLoading: false,
          isProfileContextError: false,
          userInfo: {},
          userData: {},
        });
        Sentry.setUser({ id: "xxx" });
      }
    });
  }, []);

  useEffect(() => {
    if (user?.isUserSignedIn) {
      (async () => {
        try {
          setUser({
            ...user,
            isProfileContextLoading: true,
          });
          const docSnap = await getDoc(
            doc(firestoreDb, "users", user?.userInfo?.uid)
          );

          setUser({
            ...user,
            isProfileContextLoading: false,
            userData: docSnap.exists() ? docSnap.data() : {},
          });
        } catch (err) {
          console.error(
            "err while fetching userData - ProfileContextProvider.js - mylogs :",
            err
          );
          setUser({
            ...user,
            isProfileContextLoading: false,
            userData: {},
          });
        }
      })();
    }
  }, [triggerProfileContextRes]);

  return (
    <>
      {!user?.isProfileContextLoading && (
        <ProfileContext.Provider
          value={{
            user,
            triggerProfileContextRes,
            setTriggerProfileContextRes,
          }}
        >
          {children}
        </ProfileContext.Provider>
      )}
    </>
  );
};

export default ProfileContextProvider;
