import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { WarningAmber } from "@mui/icons-material";
import { primaryColor } from "../utils/color";

export const WarningActionModal = ({
  isOpen,
  setIsOpen,
  wrnText,
  actionText,
  actionFunction,
}) => {
  return (
    <>
      <Box>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
          <Box>
            <Stack alignItems="center">
              <Spacer space={20} />
              <WarningAmber
                sx={{
                  fontSize: "70px",
                  color: primaryColor[800],
                  paddingX: "40px",
                }}
              />
              <Spacer space={15} />
              <Typography textAlign="center" sx={{ paddingX: "15px" }}>
                {wrnText}
              </Typography>
              <Spacer space={25} />
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                sx={{ paddingX: "40px" }}
              >
                <Button variant="outlined" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={actionFunction}>
                  {actionText}
                </Button>
              </Stack>
              <Spacer space={20} />
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
