import { Box } from "@mui/material";
import { PlanHeader } from "../components/plan-header";
import bodyContent from "../content/privacy-policy.json";
import { primaryBgColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { MainFooter } from "../components/MainFooter";
import { ContentBody } from "../components/content-body";

export const PrivacyPolicy = () => {
  return (
    <>
      <PlanHeader header1="Privacy" header2="Policy" />
      <Spacer space={12} />
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <ContentBody bodyContent={bodyContent} />
        <Spacer space={16} />
        <MainFooter />
      </Box>
    </>
  );
};
