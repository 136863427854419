import { Box, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { cardColor, primaryColor } from "../utils/color";
import { Link } from "react-router-dom";

export const MainMiniPropertyCard = ({ postData }) => {
  const { postInfo } = postData || {};

  return (
    <>
      <Box
        sx={{
          borderRadius: "5px",
          width: "75%",
          textDecoration: "none",
          bgcolor: `${cardColor}`,
        }}
        component={Link}
        to="/view-property"
        state={{
          data: postData,
        }}
      >
        <Box>
          <img
            style={{
              width: "250px",
              aspectRatio: "16/9",
              objectFit: "fill",
              borderRadius: "5px",
            }}
            src={postData?.media?.hall[0] || postData?.media?.bedrooms[0]}
            alt=""
          />
        </Box>
        <Spacer space={1} />
        <Box
          sx={{
            py: "3px",
          }}
        >
          <Box sx={{ px: "10px" }}>
            <Stack spacing={-0.8}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                    color: "black",
                  }}
                >
                  Rs: {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")}{" "}
                  / Month
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "'Lumanosimo', serif",
                    color: "black",
                  }}
                >
                  {postInfo?.bhk} BHK
                </Typography>
              </Stack>
              <Typography
                color={primaryColor[900]}
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontFamily: "Neuton, serif",
                }}
              >
                {postInfo?.address?.locality}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
