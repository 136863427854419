import {
  and,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { async } from "@firebase/util";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";

export const FilterResultsContext = createContext();

export const FilterResultsContextProvider = ({ children }) => {
  const [filterResultsRes, setFilterResultsRes] = useState({
    isError: false,
    isLoading: false,
    filterResultsData: null,
  });
  const [mainFilterInfoProvider, setMainFilterInfoProvider] = useState(null);

  useEffect(() => {
    setFilterResultsRes({
      isError: false,
      isLoading: false,
      filterResultsData: null,
    });
    if (mainFilterInfoProvider) {
      setFilterResultsRes({
        isError: false,
        isLoading: true,
        filterResultsData: null,
      });
      (async () => {
        try {
          // throw new Error("Error getting filters documents:");
          let q = query(
            collection(firestoreDb, "posts"),
            and(
              and(
                where("postStatus", "==", "active"),
                where("paymentStatus", "==", "success")
              ),
              and(
                where(
                  "postInfo.montlyRent",
                  ">=",
                  mainFilterInfoProvider.housesPriceRange[0]
                ),
                where(
                  "postInfo.montlyRent",
                  "<=",
                  mainFilterInfoProvider.housesPriceRange[1]
                )
              )
            ),
            orderBy("postInfo.montlyRent", mainFilterInfoProvider.sortByPrice)
          );

          if (Boolean(mainFilterInfoProvider.bhk.length)) {
            q = query(
              q,
              where("postInfo.bhk", "in", mainFilterInfoProvider.bhk)
            );
          }
          if (Boolean(mainFilterInfoProvider.locality.length)) {
            q = query(
              q,
              where(
                "postInfo.address.locality",
                "in",
                mainFilterInfoProvider.locality
              )
            );
          }

          const querySnapshot = await getDocs(q);
          const filterResultsRes = [];
          querySnapshot.forEach((doc) => {
            filterResultsRes.push(doc.data());
          });
          setFilterResultsRes({
            isError: false,
            isLoading: false,
            filterResultsData: filterResultsRes,
          });
        } catch (err) {
          console.error(
            "err while searching - FilterActionSheet.js - mylogs :",
            err
          );
          setFilterResultsRes({
            isError: true,
            isLoading: false,
            filterResultsData: null,
          });
        }
      })();
    }
  }, [mainFilterInfoProvider]);

  return (
    <>
      <FilterResultsContext.Provider
        value={{
          filterResultsRes,
          setMainFilterInfoProvider,
        }}
      >
        {children}
      </FilterResultsContext.Provider>
    </>
  );
};
