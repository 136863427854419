import { useMediaQuery } from "@mui/material";
import { isDevMode } from "../utils/is-dev-mode";
import FLAGS from "../flags/flags";
import { DesktopViewNotAvailableBanner } from "./desktop-not-available-banner";

export const MobileView = ({ children }) => {
  const isSmallDevices = useMediaQuery("(max-width:520px)");

  return isSmallDevices || (isDevMode() && FLAGS.DESKTOP_VIEW) ? (
    <>{children}</>
  ) : (
    <>
      <DesktopViewNotAvailableBanner />
    </>
  );
};
