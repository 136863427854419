import { Box, Stack } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryBgColor } from "../utils/color";
import { useContext, useEffect } from "react";
import { ProfileContext } from "../context/ProfileContextProvider";
import { MainPropertyCard } from "../components/MainPropertyCard";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { UseMessagingInfo } from "../hooks/use-messaging-info";
import { MyPostsHeader } from "../components/my-posts-header";
import { TextLoader } from "../components/text-loader";
import { ListPropertyScreen } from "../components/list-property-screen";
import { TextOkayModal } from "../modals/text-okay";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { TitleTextActionModal } from "../modals/title-text-action";

export const MyPosts = () => {
  const { user } = useContext(ProfileContext);
  const { userInfo, isUserSignedIn } = user;

  const { myPostsRes, setTriggerMyPostsRes } = useContext(MyPostsContext);

  const {
    messagingInfo,
    messagingInfoModalStatus,
    setMessagingInfoModalStatus,
    saveMessagingDeviceToken,
  } = UseMessagingInfo(userInfo?.uid);

  // To trigger myPostsRes when the user navigates to this page
  useEffect(() => {
    setTriggerMyPostsRes(true);
  }, []);

  if (myPostsRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (myPostsRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  if (myPostsRes?.myPostsData?.length === 0) {
    return (
      <>
        <MyPostsHeader
          messagingInfo={messagingInfo}
          messagingInfoModalStatus={messagingInfoModalStatus}
          setMessagingInfoModalStatus={setMessagingInfoModalStatus}
        />
        <ListPropertyScreen isUserSignedIn={isUserSignedIn} />
      </>
    );
  }

  return (
    <>
      <TitleTextActionModal
        value="isAskingPermissionModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        title="Notifications"
        text="Enable notifications to get notify when you have new request for
        your properties"
        actionText="Enable"
        actionFunction={saveMessagingDeviceToken}
      />
      <TitleTextOkayModal
        value="isRequestPermissionSuccessModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        title="Notifications Turned On"
        text="You will get notifications when you have new request for your
        properties."
      />
      <TextOkayModal
        value="isRequestPermissionModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        text="To enable notification, Please Allow notification from App
        setting."
      />
      <Box>
        <MyPostsHeader
          messagingInfo={messagingInfo}
          messagingInfoModalStatus={messagingInfoModalStatus}
          setMessagingInfoModalStatus={setMessagingInfoModalStatus}
        />
        <Box sx={{ bgcolor: `${primaryBgColor}` }}>
          <Spacer space={20} />
          <Stack spacing={3} sx={{ paddingX: "12px" }}>
            {myPostsRes?.myPostsData?.map((postData, i) => (
              <MainPropertyCard
                key={i}
                postData={postData}
                isOwnerNavigatedFromMyPosts={true}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};
