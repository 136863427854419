import { Box, Button, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { SystemUpdate } from "@mui/icons-material";
import { PLAYSTORE_URL } from "../constants/constants";
import { isDevMode } from "../utils/is-dev-mode";
import { isApp } from "../utils/isApp";

export const PlaystoreAppInstallBanner = () => {
  if (isApp() || isDevMode()) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ paddingY: "6px", paddingX: "8px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <img
              style={{
                width: "45px",
                height: "45px",
                borderRadius: "10px",
              }}
              alt=""
              src={require("../images/okhla-homes-logo.jpg")}
            />
          </Box>
          <Stack alignSelf="flex-start">
            <Typography
              sx={{
                color: primaryColor[800],
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Neuton, serif",
              }}
            >
              Switch To Playstore App
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              For Better Experience
            </Typography>
          </Stack>
          <Button
            variant="contained"
            endIcon={<SystemUpdate />}
            onClick={() => window.open(PLAYSTORE_URL, "_self")}
            sx={{
              paddingY: "5px",
              paddingX: "10px",
            }}
          >
            Install
          </Button>
        </Stack>
      </Box>
    </>
  );
};
