export const initialPostPropertyInfo = {
  postType: "",
  preference: [],
  availableFrom: new Date(),
  area: null,
  block: "",
  floor: "",
  locality: "",
  nearBy: "",
  fullName: "",
  montlyRent: "",
  security: "",
  bhk: "",
  parking: { bike: false, car: false },
  lift: false,
  furnishment: "",
  amenities: [],
};

export const initailMedia = {
  uploadedMedia: {
    hall: [],
    bedrooms: [],
    kitchen: [],
    bathroom: [],
    front: [],
  },
  displayMedia: {
    hall: [],
    bedrooms: [],
    kitchen: [],
    bathroom: [],
    front: [],
  },
};

export const postPropertyInfoReducer = (postPropertyInfo, action) => {
  switch (action.type) {
    case "reset":
      return initialPostPropertyInfo;
    case "fetch":
      return {
        ...action.value?.postInfo,
        postType: action.value?.postType,
        block: action.value?.postInfo?.address?.block,
        floor: action.value?.postInfo?.address?.floor,
        locality: action.value?.postInfo?.address?.locality,
        nearBy: action.value?.postInfo?.address?.nearBy,
      };
    default:
      return { ...postPropertyInfo, [action.filter]: action.value };
  }
};

export const mediaReducer = (media, action) => {
  switch (action.activity) {
    case "reset":
      return initailMedia;
    case "addMedia":
      return {
        ...media,
        [action.type]: {
          ...media[action.type],
          [action.category]: [
            ...media[action.type][action.category],
            ...action.value,
          ],
        },
      };
    case "removeDisplayMedia":
      return {
        ...media,
        [action.type]: {
          ...media[action.type],
          [action.category]: media[action.type][action.category].filter(
            (item) => item.name !== action.value
          ),
        },
      };
    case "removeUploaedMedia":
      return {
        ...media,
        [action.type]: {
          ...media[action.type],
          [action.category]: media[action.type][action.category]
            .map((item) => {
              if (item.name !== action.value) {
                return item;
              }
              if (item.name === action.value && item.isDownloaded === true) {
                return { ...item, isRemove: true };
              } else {
                return null;
              }
            })
            .filter((item) => {
              return item !== null;
            }),
        },
      };
    case "fetchMedia":
      const displayMedia = {};
      for (const key in action.value) {
        displayMedia[key] = action.value[key].map((url) => {
          const name = decodeURIComponent(url)
            ?.split("/")
            ?.pop()
            ?.split("?")[0];
          const isDownloaded = false;
          return { name, isDownloaded, url };
        });
      }
      const uploadedMedia = {};
      for (const key in action.value) {
        uploadedMedia[key] = action.value[key].map((url, i) => {
          const name = decodeURIComponent(url)
            ?.split("/")
            ?.pop()
            ?.split("?")[0];
          const isDownloaded = true;
          const isRemove = false;
          return { name, isDownloaded, isRemove, url };
        });
      }
      return {
        ...media,
        displayMedia: displayMedia,
        uploadedMedia: uploadedMedia,
      };
    default:
      return media;
  }
};
