import { Box } from "@mui/material";
import { PlanHeader } from "../components/plan-header";
import bodyContent from "../content/disclaimer.json";
import { primaryBgColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { MainFooter } from "../components/MainFooter";
import { ContentBody } from "../components/content-body";

export const Disclaimer = () => {
  return (
    <>
      <PlanHeader header1="Disclaimer" header2="" />
      <Spacer space={12} />
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <ContentBody bodyContent={bodyContent} />
        <Spacer space={16} />
        <MainFooter />
      </Box>
    </>
  );
};
