import { FilterResultsContextProvider } from "./context/FilterResultsContextProvider";
import HomePostsContextProvider from "./context/HomePostsContextProvider";
import { MyPostsContextProvider } from "./context/MyPostsContextProvider";
import ProfileContextProvider from "./context/ProfileContextProvider";
import { ZoomProvider } from "./context/ZoomContextProvider";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
  console.log("App started - App.js - mylogs");

  return (
    <ZoomProvider>
      <ProfileContextProvider>
        <HomePostsContextProvider>
          <MyPostsContextProvider>
            <FilterResultsContextProvider>
              <AppRoutes />
            </FilterResultsContextProvider>
          </MyPostsContextProvider>
        </HomePostsContextProvider>
      </ProfileContextProvider>
    </ZoomProvider>
  );
}

export default App;
