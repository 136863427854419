import { Box } from "@mui/material";

export const MainBanner = () => {
  return (
    <>
      <Box sx={{ paddingX: "8px" }}>
        <Box>
          <img
            style={{
              width: "100%",
              height: "130px",
              aspectRatio: "16/9",
              objectFit: "fill",
              borderRadius: "20px",
            }}
            src={require("../images/banner-1.png")}
            alt=""
          />
        </Box>
      </Box>
    </>
  );
};
