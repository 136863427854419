import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { primaryColor } from "../utils/color";

export const Loader = ({ text }) => {
  return (
    <>
      <Box>
        <FullScreen>
          <Stack alignItems="center" spacing={1.5}>
            <CircularProgress sx={{ color: primaryColor[800] }} />
            <Typography>{text}</Typography>
          </Stack>
        </FullScreen>
      </Box>
    </>
  );
};
