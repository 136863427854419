import { deepOrange } from "@mui/material/colors";

const customColor = {
  50: "#da8080",
  100: "#d26666",
  200: "#cb4d4d",
  300: "#cb4d4d",
  400: "#c33333",
  500: "#c33333",
  600: "#bc1a1a",
  700: "#bc1a1a",
  800: "#b40000",
  900: "#b40000",
};

export const MainBgColor = "#f5f7fa";
export const primaryBgColor = MainBgColor;
export const primaryColor = customColor;
export const cardColor = deepOrange;
