import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuDrawer } from "./MenuDrawer";
import { primaryColor } from "../utils/color";
import { green, grey, orange, yellow } from "@mui/material/colors";

export const MainHeader = ({ user, setMainFilterInfoProvider }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopstate = (event) => {
      window.history.pushState(null, null, window.location.pathname);
    };
    // To stop backward navigation from the dashboard
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <MenuDrawer
        user={user}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box
        bgcolor={primaryColor[800]}
        sx={{
          p: "5px",
          position: "sticky",
          top: "0",
          zIndex: "99",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            color="inherit"
            onClick={() => {
              setMainFilterInfoProvider(null);
              setIsDrawerOpen(true);
            }}
          >
            <MenuIcon sx={{ color: "white", fontSize: "28px" }} />
          </IconButton>
          <Stack
            // direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-0.5}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography
                fontSize="20px"
                fontWeight="500"
                color="black"
                fontFamily="Ultra, serif"
              >
                Okhla
              </Typography>
              <Typography
                fontSize="20px"
                fontWeight="500"
                color="white"
                fontFamily="Ultra, serif"
              >
                Homes
              </Typography>
            </Stack>
            <Typography
              fontSize="12px"
              fontWeight="600"
              color={grey[300]}
              border
              fontFamily="'Roboto Serif', serif"
            >
              Direct Rental Service
            </Typography>
          </Stack>
          <Box sx={{ width: "40px" }}></Box>
        </Stack>
      </Box>
    </>
  );
};
