import { WarningAmber } from "@mui/icons-material";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";

export const WarningOkayModal = ({ value, state, setState, warnMsg }) => {
  return (
    <>
      <Box>
        <Dialog
          open={state[value]}
          onClose={() =>
            setState({
              ...state,
              [value]: false,
            })
          }
          fullWidth
        >
          <Box
            sx={{
              padding: "20px",
            }}
          >
            <Stack alignItems="center">
              <WarningAmber
                sx={{
                  fontSize: "60px",
                  color: primaryColor[800],
                }}
              />
              <Typography textAlign="center">{warnMsg}</Typography>
              <Spacer space={10} />
              <Button
                variant="contained"
                sx={{ width: "100%", height: "24px" }}
                onClick={() => {
                  setState({
                    ...state,
                    [value]: false,
                  });
                }}
              >
                Okay
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
