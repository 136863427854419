import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";

export const UseMysavedInfo = (userUid) => {
  const [mySavedProperties, setMySavedProperties] = useState({
    isError: false,
    isLoading: true,
    data: null,
  });

  useEffect(() => {
    (async () => {
      try {
        // throw new Error("while feching my saved properties");
        const q = query(
          collection(firestoreDb, "saved"),
          where("userUid", "==", userUid),
          orderBy("time", "desc")
        );
        const querySnapshot = await getDocs(q);
        const mySavedPropertiesRes = [];
        querySnapshot.forEach((doc) => {
          mySavedPropertiesRes.push(doc.data());
        });
        setMySavedProperties({
          isError: false,
          isLoading: false,
          data: mySavedPropertiesRes,
        });
      } catch (err) {
        console.error(
          "err while fetching my saved properties - MySaved.js - mylogs :",
          err
        );
        setMySavedProperties({
          isError: true,
          isLoading: false,
          data: null,
        });
      }
    })();
  }, []);

  return { mySavedProperties };
};
