import { Stack } from "@mui/material";

export const Center = ({ children }) => {
  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </>
  );
};
