import { useContext } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { UsePaymentInfo } from "../hooks/use-payment-info";
import { primaryColor } from "../utils/color";
import {
  CheckCircleOutline,
  CurrencyRupee,
  ErrorOutline,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Spacer } from "../components/Spacer";
import { ProfileContext } from "../context/ProfileContextProvider";
import { TextLoader } from "../components/text-loader";
import { SomethingWentWrongModal } from "../components/SomethingWentWrongModal";
import { PaymentReceiptHeader } from "../components/payment-receipt-header";

export const PaymentReceipt = () => {
  const location = useLocation();
  const { navigatedFrom, navigatedData } = location.state || {};
  const postUid = navigatedData?.postUid;
  const postData = navigatedData?.postData;

  const { user } = useContext(ProfileContext);
  const { userInfo } = user;
  const { paymentInfo, setPaymentInfo } = UsePaymentInfo(postUid);
  const orderId = paymentInfo?.paymentInfoData?.items[0]?.order_id;

  if (paymentInfo?.isLoading) {
    return <TextLoader text="loading..." />;
  }

  return (
    <>
      <SomethingWentWrongModal state={paymentInfo} setState={setPaymentInfo} />
      <Box>
        <PaymentReceiptHeader
          postData={postData}
          navigatedFrom={navigatedFrom}
        />
        <Spacer space={24} />
        <Box sx={{ px: "10px" }}>
          <Stack direction="row" justifyContent="flex-start" spacing={1}>
            <Typography sx={heading1}>Post</Typography>
            <Typography color={primaryColor[900]} sx={heading1}>
              Details
            </Typography>
          </Stack>
          <Spacer space={6} />
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography>Post Id</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{postUid}</Typography>
            </Grid>
            {orderId && (
              <>
                <Grid item xs={3}>
                  <Typography>Order Id</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{orderId}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Typography> Email</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography> {userInfo?.email}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Spacer space={18} />
        {paymentInfo?.paymentInfoData?.items.map((item, index) => (
          <>
            <Box sx={{ pr: "75px" }} key={index}>
              <Box
                sx={{
                  bgcolor: ColorMap[item.status],
                  color: "white",
                  px: "14px",
                  py: "6px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {IconMap[item.status]}
                    <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                      {PaymentStatusMap[item.status]}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Spacer space={8} />
            <Box sx={{ px: "20px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={heading2}>Payment</Typography>
                  <Typography color={primaryColor[900]} sx={heading2}>
                    Details
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {new Date(item.created_at * 1000).toLocaleTimeString(
                      "en-IN"
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <Spacer space={6} />
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Typography>Date</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {new Date(item.created_at * 1000).getDate()}{" "}
                    {new Date(item.created_at * 1000).toLocaleString(
                      "default",
                      {
                        month: "long",
                      }
                    )}
                    , {new Date(item.created_at * 1000).getFullYear()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>status</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{item.status}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Stack direction="row" alignItems="center" spacing={0}>
                    <CurrencyRupee sx={{ fontSize: "16px", pt: "2px" }} />
                    <Typography>
                      {Math.floor(item.amount / 100).toLocaleString("en-IN")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Payment Id</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{item.id}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Method</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{item.method}</Typography>
                </Grid>
                {item.bank && (
                  <>
                    <Grid item xs={4}>
                      <Typography>Bank</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{item.bank}</Typography>
                    </Grid>
                  </>
                )}
                {item.error_description && (
                  <>
                    <Grid item xs={4}>
                      <Typography>Error</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{item.error_description}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Spacer space={18} />
          </>
        ))}
      </Box>
    </>
  );
};

const heading1 = {
  fontSize: "22px",
  fontWeight: "500",
  fontFamily: "Ultra, serif",
};

const heading2 = {
  fontSize: "19px",
  fontWeight: "500",
  fontFamily: "Calistoga, serif",
};

const ColorMap = {
  captured: "green",
  failed: "red",
};

const IconMap = {
  captured: <CheckCircleOutline sx={{ color: "white" }} />,
  failed: <ErrorOutline sx={{ color: "white" }} />,
};

const PaymentStatusMap = {
  captured: "Payment Success",
  failed: "Payment Failed",
};
