export const SERVICE_CHARGE_FREE_DESCRIPTION =
  "You have to pay nothing extra to owner at the time of taking the property on rent.";

export const SERVICE_CHARGE_PERCENTAGE_DESCRIPTION = (postChargePercentage) => {
  return `You have to pay just ${postChargePercentage}% of rent amount extra to owner at the time of taking the property on rent.`;
};

export const SERVICE_CHARGE_MODAL_FREE_DESCRIPTION =
  "You have to pay nothing extra to owner at the time of taking the property on rent.";

export const SERVICE_CHARGE_MODAL_PERCENTAGE_DESCRIPTION = (
  postChargePercentage
) => {
  return `You have to pay just ${postChargePercentage}% of rent amount extra to owner at the time of taking the property on rent.`;
};

export const IN_REVIEW_POST_STATUS_DESCRIPTION =
  "Post is under review, It will be live soon.";

export const REMOVED_POST_STATUS_DESCRIPTION =
  "Post is removed, It is no more available for rent.";

export const EXPIRED_POST_STATUS_DESCRIPTION =
  "Post is expired, It is no more available for rent.";

export const FAILED_PAYMENT_STATUS_DESCRIPTION =
  "Payment is failed, Don't worry your money is safe. If money was debited from your account, it will be refunded within 7-10 working days. If you have any further query, please contact us.";

export const INVALID_PAYMENT_STATUS_DESCRIPTION =
  "Payment is invalid, Don't worry your money is safe. If money was debited from your account, it will be refunded within 7-10 working days. If you have any further query, please contact us.";
