import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestoreDb } from "../firebase/firebaseConfig";

export const UseFreePost = () => {
  const [freePostStatus, setFreePostStatus] = useState({
    isSuccess: false,
    isFreePost: false,
    isLoading: false,
    isError: false,
  });

  const handleFreePost = async (postUid) => {
    setFreePostStatus({
      ...freePostStatus,
      isLoading: true,
    });

    try {
      // throw new Error("Error while posting free post");
      await updateDoc(doc(firestoreDb, `posts/${postUid}`), {
        paymentStatus: "success",
        postChargePercentage: "0",
      });
      setFreePostStatus({
        ...freePostStatus,
        isSuccess: true,
        isFreePost: true,
        isLoading: false,
      });
    } catch (error) {
      console.error("error while posting free post", error);
      setFreePostStatus({
        ...freePostStatus,
        isLoading: false,
        isError: true,
      });
    }
  };

  return { freePostStatus, setFreePostStatus, handleFreePost };
};
