import { Box, Snackbar, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";

export const SignOutSnackbar = ({ isOpen, setIsOpen }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <Box>
        <Snackbar
          autoHideDuration={3000}
          open={isOpen}
          onClose={handleClose}
          sx={{
            "&.MuiSnackbar-root": {},
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          style={{ left: "20px", right: "20px", bottom: "30px" }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: primaryColor[700],
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            <Stack direction="row" justifyContent="center">
              <Typography sx={{ color: "white" }}>
                Sign out successfully
              </Typography>
            </Stack>
          </Box>
        </Snackbar>
      </Box>
    </>
  );
};
