import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Spacer } from "./Spacer";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import FingerVerificationIllustration from "../illustrations/finger-verification.svg";
import { primaryColor } from "../utils/color";
import { Link, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { SomethingWentWrongModal } from "../modals/someting-went-wrong";
import { useEffect, useState } from "react";
import { UseSendOtp } from "../hooks/use-send-otp";

export const MobileNumberScreen = ({
  contactNumber,
  setContactNumber,
  setScreen,
  setVerificationId,
}) => {
  const location = useLocation();
  const state = location.state;

  const [isGetOptDisabled, setIsGetOptDisabled] = useState(true);
  const { sendOtpInfo, setSendOtpInfo, handleSendOtp } = UseSendOtp();

  useEffect(() => {
    setVerificationId(sendOtpInfo?.sendOtpData?.verificationId);
    if (sendOtpInfo?.sendOtpData?.responseCode === "200") {
      setScreen("2");
    }
  }, [sendOtpInfo]);

  useEffect(() => {
    if (contactNumber.length === 10) {
      setIsGetOptDisabled(false);
    } else {
      setIsGetOptDisabled(true);
    }
  }, [contactNumber]);

  return (
    <>
      <SomethingWentWrongModal
        value="isError"
        state={sendOtpInfo}
        setState={setSendOtpInfo}
      />
      <FullScreen spacing={0}>
        <Stack justifyContent="space-around" alignItems="center">
          <Spacer space={50} />
          <Box>
            <img
              width="230px"
              height="230px"
              alt=""
              src={FingerVerificationIllustration}
            />
          </Box>
          <Spacer space={30} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography color="black" sx={heading}>
              Enter
            </Typography>
            <Typography color={primaryColor[800]} sx={heading}>
              Your Mobile Number
            </Typography>
          </Stack>
          <Typography
            sx={{
              color: primaryColor[800],
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            To receive a verification code
          </Typography>
          <Spacer space={20} />
          <TextField
            sx={{
              ...removingDigitsIndicatorsStyle,
            }}
            label="Required*"
            type="number"
            size="small"
            variant="outlined"
            placeholder="mobile number"
            value={contactNumber}
            onChange={(event) => setContactNumber(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
          <Spacer space={50} />
        </Stack>

        <Stack spacing={2} sx={{ width: "100%" }} alignItems="center">
          <LoadingButton
            loading={sendOtpInfo?.isLoading}
            variant="contained"
            disabled={isGetOptDisabled}
            sx={{ width: "90%" }}
            onClick={() => handleSendOtp(contactNumber)}
          >
            Get OTP
          </LoadingButton>
          <Button
            component={Link}
            to={
              state?.navigatedFrom === "/list-property"
                ? "/"
                : state?.navigateTo || state?.navigatedFrom || "/"
            }
            state={{
              data: state?.navigatedData,
            }}
          >
            Skip
          </Button>
        </Stack>
      </FullScreen>
    </>
  );
};

const heading = {
  fontFamily: "Calistoga, serif",
  fontSize: "20px",
  fontWeight: "600",
};
