import Axios from "axios";
import { config } from "../config/config";

export const validatePaymentSignature = async (
  razorpay_payment_id,
  razorpay_signature,
  postUid
) => {
  const { VALIDATE_PAYMENT_SIGNATURE } = config();

  const { data } = await Axios.post(
    VALIDATE_PAYMENT_SIGNATURE,
    {
      razorpay_payment_id,
      key_secret: razorpay_signature,
      postUid,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
