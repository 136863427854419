import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";

export const TitleTextActionModal = ({
  value,
  state,
  setState,
  title,
  text,
  actionText,
  actionFunction,
}) => {
  return (
    <>
      <Box>
        <Dialog
          open={state[value]}
          onClose={() =>
            setState({
              ...state,
              [value]: false,
            })
          }
          fullWidth
        >
          <Box sx={{ padding: "20px" }}>
            <Stack>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: primaryColor[800],
                  fontFamily: "Calistoga, serif",
                }}
              >
                {title}
              </Typography>
              <Spacer space={3} />
              <Typography sx={{ fontSize: "15px" }}>{text}</Typography>
              <Spacer space={15} />
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setState({
                      ...state,
                      [value]: false,
                    });
                  }}
                  sx={{ height: "32px" }}
                >
                  close
                </Button>
                <Button
                  variant="contained"
                  onClick={actionFunction}
                  sx={{ height: "32px" }}
                >
                  {actionText}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
