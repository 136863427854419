import { useContext, useState } from "react";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { firestoreDb, storage } from "../firebase/firebaseConfig";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { POST_STATUS } from "../constants/constants";

export const UseSavePost = (
  postPropertyInfo,
  userUid,
  isEditPost,
  mediaToUpload,
  contactNumber,
  pendingPostInfo,
  setPendingPostInfo
) => {
  const navigate = useNavigate();
  const [postRef, setPostRef] = useState(null);
  const { triggerMyPosts, setTriggerMyPosts } = useContext(MyPostsContext);
  const [savingPostStatus, setSavingPostStatus] = useState({
    isUploadingImages: false,
    uploadingMediaInfo: {
      category: "",
      UploadingMediaNumber: null,
      totalCategoryLength: null,
      progress: null,
    },
    isSavingPost: false,
    isUploadingImagesError: false,
    isSavingPostError: false,
    status: false,
  });

  function getPathFromUrl(url) {
    const match = url.match(/\/o\/(.*?)\?alt=media/);
    if (match && match[1]) {
      return decodeURIComponent(match[1]);
    }
    return null;
  }

  async function deleteFileByUrl(downloadUrl) {
    const storagePath = getPathFromUrl(downloadUrl);
    if (!storagePath) {
      throw new Error("Invalid download URL");
    }

    const fileRef = ref(storage, storagePath);

    try {
      await deleteObject(fileRef);
    } catch (error) {
      throw new Error(error);
    }
  }

  const mediaUploadPromise = (postUid) => {
    return new Promise(function (resolve, reject) {
      let imgTotalCount = 0;
      let imgSuccessCount = 0;
      let imgFailCount = 0;
      let uploadedMedia = {
        hall: [],
        bedrooms: [],
        kitchen: [],
        bathroom: [],
        front: [],
      };

      let isEmpty = true;
      Object.entries(mediaToUpload).map(async (category) => {
        if (category[1].toString() !== "") {
          isEmpty = false;
        }
      });
      if (isEmpty) {
        return resolve(uploadedMedia);
      }

      Object.entries(mediaToUpload).map(async (category) => {
        category[1]?.map(async (media, i) => {
          imgTotalCount++;

          if (media?.isDownloaded === true) {
            if (media?.isRemove === true) {
              try {
                await deleteFileByUrl(media.url);
                imgSuccessCount++;
                if (imgSuccessCount === imgTotalCount) {
                  return resolve(uploadedMedia);
                }
              } catch (err) {
                console.error("error while deleting the image - mylogs :", err);
                imgFailCount++;
                if (imgSuccessCount + imgFailCount === imgTotalCount) {
                  return reject(false);
                }
              }
              return;
            }
            uploadedMedia[category[0]].push(media.url);
            imgSuccessCount++;
            if (imgSuccessCount === imgTotalCount) {
              return resolve(uploadedMedia);
            }
            return;
          }

          const uploadTask = uploadBytesResumable(
            ref(storage, `images/${userUid}/${postUid}/${media.name}`),
            media,
            {
              contentType: "image/jpeg",
            }
          );

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setSavingPostStatus({
                ...savingPostStatus,
                isUploadingImages: true,
                uploadingMediaInfo: {
                  category: category[0],
                  UploadingMediaNumber: i + 1,
                  totalCategoryLength: category[1]?.length,
                  progress: Math.floor(progress),
                },
              });
            },
            (error) => {
              console.error(
                "error while uploadig the image - ListProperty.js - mylogs :",
                error
              );
              imgFailCount++;
              if (imgSuccessCount + imgFailCount === imgTotalCount) {
                return reject(false);
              }
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                uploadedMedia[category[0]].push(downloadURL);
                imgSuccessCount++;
                if (imgSuccessCount === imgTotalCount) {
                  return resolve(uploadedMedia);
                }
                if (imgSuccessCount + imgFailCount === imgTotalCount) {
                  return reject(false);
                }
              });
            }
          );
        });
      });
    });
  };

  const handleSavePost = async (reference) => {
    const newPostRef = pendingPostInfo.isPendingPost
      ? pendingPostInfo?.pendingPostRef
      : doc(collection(firestoreDb, "posts"));

    if (!pendingPostInfo.isPendingPost) {
      setPendingPostInfo({
        ...pendingPostInfo,
        isPendingPost: true,
        pendingPostUid: newPostRef.id,
        pendingPostRef: newPostRef,
      });
    }
    setPostRef(newPostRef);
    const contactInfoRef = pendingPostInfo.isPendingPost
      ? doc(firestoreDb, `${newPostRef.path}/secure/contact-info`)
      : doc(firestoreDb, `${newPostRef.path}/secure/contact-info`);

    const postInfo = {
      preference: postPropertyInfo?.preference,
      availableFrom: postPropertyInfo?.availableFrom,
      area: postPropertyInfo?.area,
      address: {
        block: postPropertyInfo?.block,
        floor: postPropertyInfo?.floor,
        locality: postPropertyInfo?.locality,
        nearBy: postPropertyInfo?.nearBy,
      },
      fullName: postPropertyInfo?.fullName,
      montlyRent: postPropertyInfo?.montlyRent,
      security: postPropertyInfo?.security,
      bhk: postPropertyInfo?.bhk,
      parking: postPropertyInfo?.parking,
      lift: postPropertyInfo?.lift,
      furnishment: postPropertyInfo?.furnishment,
      amenities: postPropertyInfo?.amenities,
    };

    try {
      setSavingPostStatus({ ...savingPostStatus, isUploadingImages: true });
      // throw new Error("test error");
      const uploadedMedia = await mediaUploadPromise(newPostRef.id);
      setSavingPostStatus({ ...savingPostStatus, isUploadingImages: false });

      try {
        setSavingPostStatus({ ...savingPostStatus, isSavingPost: true });
        // throw new Error("test error");
        const uploadPostInfo = {
          postTime: isEditPost ? pendingPostInfo?.pendingPostTime : new Date(),
          postUid: newPostRef.id,
          ownerUid: userUid,
          postType: postPropertyInfo?.postType,
          paymentStatus: isEditPost ? "success" : "pending",
          postStatus: POST_STATUS.IN_REVIEW,
          postInfo,
          media: uploadedMedia,
        };

        const uploadContactInfo = {
          ownerContactNumber:
            postPropertyInfo?.postType === "direct-calling"
              ? contactNumber
              : null,
          ownerUid: userUid,
        };

        const newPostPromise = pendingPostInfo.isPendingPost
          ? updateDoc(newPostRef, uploadPostInfo)
          : setDoc(newPostRef, uploadPostInfo);

        const contactInfoPromise = pendingPostInfo.isPendingPost
          ? updateDoc(contactInfoRef, uploadContactInfo)
          : setDoc(contactInfoRef, uploadContactInfo);

        await Promise.all([newPostPromise, contactInfoPromise]);

        if (isEditPost) {
          setTriggerMyPosts(!triggerMyPosts);
          navigate("/view-property", {
            replace: true,
            state: { data: uploadPostInfo, navigatedFrom: "/list-property" },
          });
        }

        setSavingPostStatus({
          ...savingPostStatus,
          isSavingPost: false,
          status: reference === "SAVE_CONTINUE_POST" ? true : false,
        });
      } catch (err) {
        console.error(
          "err while saving post - ListProperty.js - mylogs :",
          err
        );
        setSavingPostStatus({
          ...savingPostStatus,
          isSavingPost: false,
          isSavingPostError: true,
        });
      }
    } catch (err) {
      console.error(
        "err while uploading images - ListProperty.js - mylogs :",
        err
      );
      setSavingPostStatus({
        ...savingPostStatus,
        isUploadingImages: false,
        isUploadingImagesError: true,
      });
    }
  };

  return { savingPostStatus, postRef, setSavingPostStatus, handleSavePost };
};
