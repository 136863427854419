import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { primaryColor } from "../utils/color";

export const PaymentReceiptHeader = ({ postData, navigatedFrom }) => {
  return (
    <>
      <Box
        bgcolor={primaryColor[800]}
        sx={{ p: "5px", position: "sticky", top: "0", zIndex: "5" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            color="inherit"
            component={Link}
            to={navigatedFrom}
            state={{
              data: postData,
              isOwnerNavigatedFromMyPosts: true,
              navigatedFrom: "/my-posts",
            }}
          >
            <ArrowBack sx={{ color: "white" }} />
          </IconButton>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="black"
              fontFamily="Ultra, serif"
            >
              Payment
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="white"
              fontFamily="Ultra, serif"
            >
              Details
            </Typography>
          </Stack>
          <Box sx={{ width: "10px" }}></Box>
        </Stack>
      </Box>
    </>
  );
};
