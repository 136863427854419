import { Box, Button, Stack } from "@mui/material";
import { primaryBgColor } from "../utils/color";

export const ScreenBottomButton = ({ text, action, isButtonDisabled }) => {
  return (
    <>
      <Box>
        <Stack
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            left: "0px",
            zIndex: "99",
            paddingBottom: "30px",
            paddingTop: "12px",
            bgcolor: `${primaryBgColor}`,
          }}
        >
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            sx={{ width: "95%", boxShadow: "6" }}
            onClick={action}
          >
            {text}
          </Button>
        </Stack>
      </Box>
    </>
  );
};
