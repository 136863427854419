import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { isDevMode } from "../utils/is-dev-mode";
import { POST_STATUS, mediaSorted } from "../constants/constants";
import { Cancel } from "@mui/icons-material";
import { useEffect, useMemo } from "react";
import { mediaLength } from "../helper/helper";
import { useNavigate } from "react-router-dom";
import { useZoom } from "../context/ZoomContextProvider";

export const FullScreenImageCarousel = ({
  media,
  mediaIndexCount,
  setMediaIndexCount,
  setIsSliderOpen,
  postStatus,
}) => {
  const navigate = useNavigate();
  const { setIsZoomEnabled } = useZoom();
  const mediaLen = useMemo(() => mediaLength(media), [media]);
  const isPostActive = postStatus === POST_STATUS.ACTIVE;

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopstate = (event) => {
      setIsSliderOpen(false);
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    setIsZoomEnabled(true);

    return () => {
      setIsZoomEnabled(false);
    };
  }, [setIsZoomEnabled]);

  return (
    <>
      <Box sx={{ backgroundColor: "black", position: "relative" }}>
        <Carousel
          selectedItem={mediaIndexCount - 1}
          autoPlay={false}
          infiniteLoop={true}
          transitionTime={250}
          showArrows={isDevMode() ? true : false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          swipeScrollTolerance={30}
          preventMovementUntilSwipeScrollTolerance={true}
          onChange={(index) => setMediaIndexCount(index + 1)}
        >
          {mediaSorted?.map((category, i) => {
            return media[category]?.map((property, j) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                  sx={{ opacity: isPostActive ? "1" : "0.5" }}
                >
                  <Box key={j} sx={{ position: "relative" }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-15%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: "14px",
                        color: "white",
                      }}
                    >
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </Typography>
                    <img
                      style={{
                        textAlign: "center",
                        width: "100%",
                        height: "40vh",
                        aspectRatio: "16/9",
                        objectFit: "fill",
                      }}
                      src={property}
                      alt=""
                    />
                  </Box>
                </Stack>
              );
            });
          })}
        </Carousel>
        <Typography
          sx={{
            position: "absolute",
            top: "75%",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "14px",
            color: "white",
            opacity: isPostActive ? "1" : "0.5",
          }}
        >
          {mediaIndexCount}/{mediaLen}
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            top: "12px",
            right: "8px",
          }}
          onClick={() => {
            setIsSliderOpen(false);
            navigate(-1);
          }}
        >
          <Cancel sx={{ fontSize: "30px", opacity: "0.6", color: "white" }} />
        </IconButton>
      </Box>
    </>
  );
};
