import { Box, Divider, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor, cardColor } from "../utils/color";
import { Carousel } from "react-responsive-carousel";
import { Link, useLocation } from "react-router-dom";
import { Bookmark, Call } from "@mui/icons-material";
import { POST_STATUS, mediaSorted } from "../constants/constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { green, red } from "@mui/material/colors";
import { getPostTags } from "../utils/generals";

export const MainPropertyCard = ({ postData, isOwnerNavigatedFromMyPosts }) => {
  const { postInfo, paymentStatus, postStatus, postType } = postData || {};
  const { pathname } = useLocation();
  const isMyPostsPage = pathname.includes("my-posts");
  const isPostActive = postData?.postStatus === POST_STATUS.ACTIVE;
  const postTags = getPostTags(
    isMyPostsPage,
    paymentStatus,
    postStatus,
    postType
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: `3`,
          bgcolor: `${cardColor}`,
          textDecoration: "none",
        }}
        component={Link}
        to="/view-property"
        state={{
          data: postData,
          isOwnerNavigatedFromMyPosts,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Carousel
            autoPlay={false}
            infiniteLoop={true}
            transitionTime={250}
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            swipeScrollTolerance={40}
            preventMovementUntilSwipeScrollTolerance={true}
          >
            {mediaSorted?.map((category, i) => {
              return postData?.media[category]?.map((property, i) => (
                <Box key={i}>
                  <Box>
                    <img
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        objectFit: "fill",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                      src={property}
                      alt=""
                    />
                  </Box>
                </Box>
              ));
            })}
          </Carousel>
          {postTags?.map((postTag, i) => (
            <Box
              sx={{
                position: "absolute",
                top: i * 40 + 25 + "px",
                left: "0px",
                zIndex: "9",
              }}
              key={i}
            >
              <Box
                sx={{
                  bgcolor: postTag?.variantColor,
                  color: "white",
                  px: "8px",
                  py: "1px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Bookmark fontSize="inherit" />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {postTag?.tag}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            py: "8px",
            opacity: isPostActive ? "1" : "0.5",
          }}
        >
          <Box sx={{ px: "20px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "'Lumanosimo', serif",
                  color: "black",
                }}
              >
                Rs: {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")} /
                Month
              </Typography>
              <Typography
                sx={{
                  color: primaryColor[900],
                  fontSize: "14px",
                  fontFamily: "'Lumanosimo', serif",
                }}
              >
                {postInfo?.bhk} BHK
              </Typography>
            </Stack>
          </Box>
          <Spacer space={6} />
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <Spacer space={4} />
          <Box sx={{ px: "25px" }}>
            <Stack spacing={-1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  color={primaryColor[900]}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Calistoga, serif",
                  }}
                >
                  {postInfo?.address?.locality}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "Neuton, serif",
                  }}
                >
                  {postInfo.furnishment}
                </Typography>
              </Stack>
              <Stack spacing={-1}>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Neuton, serif",
                  }}
                >
                  {postInfo?.address?.block} - block, {postInfo?.address?.floor}{" "}
                  floor
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "Neuton, serif",
                  }}
                >
                  Near By : {postInfo.address.nearBy}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Spacer space={3} />
        </Box>
      </Box>
    </>
  );
};
