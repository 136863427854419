import { useState } from "react";
import { verifyPromoCode } from "../services/verify-promo-code";


export const UseVerifyPromoCode = () => {
  const [postAmountModalInfo, setPostAmountModalInfo] = useState({
    isLoading: false,
    isError: false,
    isCouponCode: false,
    couponCode: "",
    postChargePercentage: null,
    promoCodeStatus: "",
  });

  const handlePromoCode = async () => {
    setPostAmountModalInfo({
      ...postAmountModalInfo,
      isLoading: true,
    });

    try {
      const res = await verifyPromoCode(postAmountModalInfo.couponCode);
      setPostAmountModalInfo({
        ...postAmountModalInfo,
        promoCodeStatus: res.promoCodeStatus,
        postChargePercentage: res.postChargePercentage,
        isError: false,
        isLoading: false,
      });
    } catch (err) {
      console.error("error while verifying promo code - mylogs", err);
      setPostAmountModalInfo({
        ...postAmountModalInfo,
        isError: true,
        isLoading: false,
      });
    }
  };

  return { postAmountModalInfo, setPostAmountModalInfo, handlePromoCode };
};
