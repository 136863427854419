import { Warning } from "@mui/icons-material";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/color";

export const SomethingWentWrongModal = ({ state, setState }) => {
  return (
    <>
      <Dialog
        open={state?.isError}
        onClose={() =>
          setState({
            ...state,
            isError: false,
          })
        }
        fullWidth
      >
        <Box sx={{ padding: "20px" }}>
          <Stack alignItems="center">
            <Warning sx={{ color: primaryColor[800], fontSize: "75px" }} />
            <Spacer space={3} />
            <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
              Oops!
            </Typography>
            <Typography>Something went wrong</Typography>
            <Typography>While processing your request</Typography>
          </Stack>
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            setState(
              setState({
                ...state,
                isError: false,
              })
            );
          }}
        >
          Dismiss
        </Button>
      </Dialog>
    </>
  );
};
