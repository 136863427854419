import { Box, Stack } from "@mui/material";

export const FullScreen = ({ children, bgcolor, spacing }) => {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "0px",
          top: "0px",
          left: "0px",
          right: "0px",
          bgcolor: `${bgcolor}`,
        }}
      >
        <Stack
          justifyContent="space-around"
          alignItems="center"
          spacing={spacing}
          sx={{ height: "100%", width: "100%" }}
        >
          {children}
        </Stack>
      </Box>
    </>
  );
};
