import { Box } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { MainHeader } from "../components/MainHeader";
import { FilterActionSheet } from "../components/FilterActionSheet";
import { PropertyCardSection } from "../components/PropertyCardSection";
import { MainFooter } from "../components/MainFooter";
import { primaryBgColor } from "../utils/color";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useContext } from "react";
import { FilterResults } from "../components/FilterResults";
import { HomePostsContext } from "../context/HomePostsContextProvider";
import { FilterResultsContext } from "../context/FilterResultsContextProvider";
import { ErrorParachuteComponent } from "../components/ErrorParachuteComponent";
import { Loader } from "../components/loader";
import { CarouselBanner } from "../components/carousel-banner";
import { PlaystoreAppInstallBanner } from "../components/playstore-app-install-banner";
import { MainBanner } from "../components/main-banner";

export const Home = () => {
  // ** <<--- STATES --->> ** //
  const { user } = useContext(ProfileContext);
  const { homePostsInfo, isHomePostsLoading, isHomePostsError } =
    useContext(HomePostsContext);
  const { filterResultsRes, setMainFilterInfoProvider } =
    useContext(FilterResultsContext);

  // ** <<--- CONSTANTS --->> ** //
  const isDashboardLoading = filterResultsRes?.isLoading || isHomePostsLoading;
  const isDashboardError = isHomePostsError || filterResultsRes?.isError;

  return (
    <>
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <PlaystoreAppInstallBanner />
        <MainHeader
          user={user}
          setMainFilterInfoProvider={setMainFilterInfoProvider}
        />
        <Spacer space={10} />
        <MainBanner />
        <Spacer space={12} />
        <Box sx={{ marginX: "6px" }}>
          <FilterActionSheet
            setMainFilterInfoProvider={setMainFilterInfoProvider}
          />
        </Box>
        <Spacer space={12} />
        {isDashboardLoading ? (
          <Loader text="Loading..." />
        ) : isDashboardError ? (
          <Box>
            <ErrorParachuteComponent />
          </Box>
        ) : filterResultsRes?.filterResultsData ? (
          <FilterResults
            filterResults={filterResultsRes?.filterResultsData}
            setMainFilterInfoProvider={setMainFilterInfoProvider}
          />
        ) : (
          <PropertyCardSection homePostsInfo={homePostsInfo} />
        )}
        <Spacer space={30} />
        <MainFooter setFooterAtBottom={isDashboardLoading} />
      </Box>
    </>
  );
};
