import { Box, List, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import { useContext, useState } from "react";
import { ProfileContext } from "../context/ProfileContextProvider";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { ListPropertyCard } from "../components/ListPropertyCard";
import { useSwipeable } from "react-swipeable";
import { UseMyrequestInfo } from "../hooks/use-myrequest-info";
import { TitleTextActionModal } from "../modals/title-text-action";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { TextOkayModal } from "../modals/text-okay";
import { UseMessagingInfo } from "../hooks/use-messaging-info";
import { MyRequestsHeader } from "../components/my-requests-header";
import { TextLoader } from "../components/text-loader";

export const MyRequests = () => {
  const location = useLocation();
  const state = location.state;
  const stateTabValue = state?.navigatedData;

  const { user } = useContext(ProfileContext);
  const { userInfo } = user;

  const [tabValue, setTabValue] = useState(stateTabValue || "1");

  const { myRequestsRes } = UseMyrequestInfo(userInfo?.uid);
  const {
    messagingInfo,
    messagingInfoModalStatus,
    setMessagingInfoModalStatus,
    saveMessagingDeviceToken,
  } = UseMessagingInfo(userInfo?.uid);

  const handlers = useSwipeable({
    delta: 20,
    onSwipedLeft: () =>
      parseInt(tabValue, 10) < 3 &&
      setTabValue(String(parseInt(tabValue, 10) + 1)),
    onSwipedRight: () =>
      parseInt(tabValue, 10) > 1 &&
      setTabValue(String(parseInt(tabValue, 10) - 1)),
  });

  if (myRequestsRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (myRequestsRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  return (
    <>
      <TitleTextActionModal
        value="isAskingPermissionModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        title="Notifications"
        text="Enable notifications to get notify when you have new request for
        your properties"
        actionText="Enable"
        actionFunction={saveMessagingDeviceToken}
      />
      <TitleTextOkayModal
        value="isRequestPermissionSuccessModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        title="Notifications Turned On"
        text="You will get notifications when you have new request for your
        properties."
      />
      <TextOkayModal
        value="isRequestPermissionModalOpen"
        state={messagingInfoModalStatus}
        setState={setMessagingInfoModalStatus}
        text="To enable notification, Please Allow notification from App
        setting."
      />
      <Box>
        <MyRequestsHeader
          messagingInfo={messagingInfo}
          messagingInfoModalStatus={messagingInfoModalStatus}
          setMessagingInfoModalStatus={setMessagingInfoModalStatus}
        />
        <Box sx={{ width: "100%", height: "100vh" }} {...handlers}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="fullWidth"
              >
                <Tab label="Pending" value="1" />
                <Tab label="Approved" value="2" />
                <Tab label="Rejected" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {myRequestsRes?.myRequestsData?.pending?.map((request, i) => (
                    <ListPropertyCard
                      key={i}
                      request={request}
                      navigatedFrom={"my-requests"}
                      tabValue={tabValue}
                    />
                  ))}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {myRequestsRes?.myRequestsData?.approved?.map(
                    (request, i) => (
                      <ListPropertyCard
                        key={i}
                        request={request}
                        navigatedFrom={"my-requests"}
                        tabValue={tabValue}
                      />
                    )
                  )}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {myRequestsRes?.myRequestsData?.rejected?.map(
                    (request, i) => (
                      <ListPropertyCard
                        key={i}
                        request={request}
                        navigatedFrom={"my-requests"}
                        tabValue={tabValue}
                      />
                    )
                  )}
                </List>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
