import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";

export const TitleTextOkayModal = ({ value, state, setState, title, text }) => {
  return (
    <>
      <Box>
        <Dialog
          open={state[value]}
          onClose={() =>
            setState({
              ...state,
              [value]: false,
            })
          }
          fullWidth
        >
          <Box sx={{ padding: "20px" }}>
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: primaryColor[800],
                  fontFamily: "Calistoga, serif",
                }}
              >
                {title}
              </Typography>
              <Spacer space={6} />
              <Typography sx={{ fontSize: "14px" }}>{text}</Typography>
              <Spacer space={20} />
              <Button
                variant="contained"
                sx={{ width: "100%", height: "24px" }}
                onClick={() => {
                  setState({
                    ...state,
                    [value]: false,
                  });
                }}
              >
                Okay
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
