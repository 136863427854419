import { POST_CHARGE_PERCENTAGE } from "../constants/constants";

export const getPostAmountInPaisa = (
  rentAmount,
  promoCodeStatus,
  postChargePercentage
) => {
  const chargePercentage =
    promoCodeStatus === "VALID" ? postChargePercentage : POST_CHARGE_PERCENTAGE;

  return Math.floor((chargePercentage / 100) * rentAmount * 100);
};
