import { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Spacer } from "./Spacer";
import { primaryColor } from "../utils/color";
import { ErrorOutline } from "@mui/icons-material";
import OtpInput from "react-otp-input";
import { grey, red } from "@mui/material/colors";
import OtpVerificationIllustration from "../illustrations/otp-verification.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import { UseSubmitOtp } from "../hooks/use-submit-otp";

export const OtpScreen = ({
  userUid,
  setScreen,
  contactNumber,
  verificationId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [resendCountDown, setResendCountDown] = useState(28);
  const [otp, setOtp] = useState("");
  const [isResendOtpDisabled, setIsResendOtpDisabled] = useState(true);
  const [isSumbitOtpDisabled, setIsSumbitOtpDisabled] = useState(true);
  const { submitOtpInfo, setSubmitOtpInfo, handleSubmitOtp } = UseSubmitOtp(
    userUid,
    verificationId,
    otp,
    contactNumber
  );

  useEffect(() => {
    if (submitOtpInfo?.submitOtpStatus === "success") {
      if (state?.navigatedFrom) {
        navigate(state?.navigatedFrom, {
          state: { data: state?.navigatedData },
        });
      } else {
        navigate("/");
      }
    }
  }, [submitOtpInfo]);

  useEffect(() => {
    if (otp.length === 4) {
      setIsSumbitOtpDisabled(false);
    } else {
      setIsSumbitOtpDisabled(true);
    }
  }, [otp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setResendCountDown((prev) => {
        if (prev <= 1) {
          setIsResendOtpDisabled(false);
          clearInterval(intervalId);
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <FullScreen spacing={-2}>
        <Stack justifyContent="space-around" alignItems="center">
          <Spacer space={50} />
          <Box>
            <img
              width="230px"
              height="230px"
              alt=""
              src={OtpVerificationIllustration}
            />
          </Box>
          <Spacer space={20} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography color="black" sx={heading}>
              OTP
            </Typography>
            <Typography color={primaryColor[800]} sx={heading}>
              Verification
            </Typography>
          </Stack>
          <Typography
            sx={{
              color: primaryColor[800],
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Enter OTP sent to +91{contactNumber}
          </Typography>
          <Spacer space={20} />
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>{"-"}</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpInputStyle}
          />

          {submitOtpInfo?.isError && (
            <>
              <Spacer space={15} />
              <Stack alignItems="center" spacing={-0.5}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <ErrorOutline sx={{ fontSize: "16px", color: red[800] }} />
                  <Typography
                    sx={{
                      color: red[800],
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Error !!
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography
                    sx={{
                      color: red[800],
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Please check your OTP or
                  </Typography>
                  <Typography
                    sx={{
                      color: red[800],
                      fontSize: "12px",
                      fontWeight: "600",
                      textDecorationLine: "underline",
                    }}
                    onClick={() => navigate("/")}
                  >
                    try again later
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}

          <Spacer space={25} />
          <Stack alignItems="center" spacing={-1}>
            <Stack direction="row" alignItems="center" spacing={-0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Didn't receive OTP ?
              </Typography>
              <Button
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                disabled={isResendOtpDisabled}
                onClick={() => {
                  setScreen("1");
                  setSubmitOtpInfo({
                    isError: false,
                    isLoading: false,
                    submitOtpStatus: null,
                  });
                }}
              >
                Retry
              </Button>
            </Stack>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "500",
                color: grey[700],
              }}
            >
              ( Resend code in {resendCountDown} seconds )
            </Typography>
          </Stack>
          <Spacer space={50} />
        </Stack>

        <LoadingButton
          loading={submitOtpInfo?.isLoading}
          variant="contained"
          disabled={isSumbitOtpDisabled}
          sx={{ width: "90%" }}
          onClick={handleSubmitOtp}
        >
          Submit
        </LoadingButton>
      </FullScreen>
    </>
  );
};

const heading = {
  fontFamily: "Calistoga, serif",
  fontSize: "20px",
  fontWeight: "600",
};

const otpInputStyle = {
  width: "40px",
  height: "40px",
  fontSize: "20px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  margin: "0 6px",
};
