import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useContext } from "react";

export const ProtectedRoutes = () => {
  const { user } = useContext(ProfileContext);
  const { isUserSignedIn } = user;

  const location = useLocation();

  return isUserSignedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{
        navigatedFrom: location?.pathname,
        navigateTo: "/",
      }}
    />
  );
};
