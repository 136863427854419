import { Box, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { splitStringBasedOnFirstSpace } from "../utils/generals";
import { Spacer } from "../components/Spacer";
import { RadioButtonChecked } from "@mui/icons-material";

export const ContentBody = ({ bodyContent }) => {
  return (
    <>
      <Box sx={{ marginX: "8px" }}>
        {bodyContent?.map((content, index) => {
          return (
            <>
              <Box key={index}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  spacing={0.5}
                >
                  <Typography sx={titleStyle}>
                    {splitStringBasedOnFirstSpace(content?.title)[0]}
                  </Typography>
                  <Typography color={primaryColor[900]} sx={titleStyle}>
                    {splitStringBasedOnFirstSpace(content?.title)[1]}
                  </Typography>
                </Stack>
                <Spacer space={4} />
                {content?.description?.map((desc, index) => (
                  <>
                    <Typography
                      sx={subTitleStyle}
                      dangerouslySetInnerHTML={{
                        __html: desc?.subTitle,
                      }}
                    />
                    <Spacer space={3} />
                    <Typography
                      sx={textStyle}
                      dangerouslySetInnerHTML={{
                        __html: desc?.text,
                      }}
                    />
                    {Boolean(desc?.listItems?.length) && <Spacer space={4} />}
                    {desc?.listItems?.map((listItem, index) => {
                      return (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <RadioButtonChecked
                            sx={{
                              fontSize: "15px",
                              paddingTop: "5px",
                              color: primaryColor[700],
                            }}
                          />
                          <Typography
                            key={index}
                            sx={textStyle}
                            dangerouslySetInnerHTML={{
                              __html: listItem,
                            }}
                          />
                        </Stack>
                      );
                    })}
                    <Spacer space={3} />
                  </>
                ))}
              </Box>
              <Spacer space={10} />
            </>
          );
        })}
      </Box>
    </>
  );
};

const titleStyle = {
  fontSize: "21px",
  fontWeight: "500",
  fontFamily: "Calistoga, serif",
};

const subTitleStyle = {
  fontSize: "17px",
  fontWeight: "600",
};

const textStyle = {
  fontSize: "17px",
};
