import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";

export const UseMyrequestInfo = (userUid) => {
  const [myRequestsRes, setMyRequestsRes] = useState({
    isLoading: true,
    isError: false,
    myRequestsData: {
      pending: [],
      approved: [],
      rejected: [],
    },
  });

  useEffect(() => {
    (async () => {
      try {
        // throw new Error("Error while getting my requests");
        const q = query(
          collection(firestoreDb, "requests"),
          where("userUid", "==", userUid),
          orderBy("time", "desc")
        );
        const querySnapshot = await getDocs(q);
        const myRequestFilterRes = [];
        querySnapshot.forEach((doc) => {
          myRequestFilterRes.push(doc.data());
        });

        const pendingRequests = [];
        myRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Pending") {
            pendingRequests.push(doc);
          }
        });

        const approvedRequests = [];
        myRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Approved") {
            approvedRequests.push(doc);
          }
        });

        const rejectedRequests = [];
        myRequestFilterRes.forEach((doc) => {
          if (doc?.requestStatus === "Rejected") {
            rejectedRequests.push(doc);
          }
        });

        setMyRequestsRes({
          isError: false,
          isMyRequestsResLoading: false,
          myRequestsData: {
            pending: pendingRequests,
            approved: approvedRequests,
            rejected: rejectedRequests,
          },
        });
      } catch (err) {
        console.error(
          "err while getting my posts - FilterActionSheet.js - mylogs :",
          err
        );
        setMyRequestsRes({
          isError: true,
          isMyRequestsResLoading: false,
          myRequestsData: {
            pending: [],
            approved: [],
            rejected: [],
          },
        });
      }
    })();
  }, []);

  return { myRequestsRes };
};
