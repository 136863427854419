import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Center } from "../components/Center";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { grey } from "@mui/material/colors";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import { statesAndUTs } from "../constants/constants";

export const RequestFormModal = ({
  requestInfo,
  requestInfoDispatch,
  handleRequestSend,
  isRequestModalOpen,
  setIsRequestModalOpen,
}) => {
  const [isRequestSendDisabled, setIsRequestSendDisabled] = useState(false);

  useEffect(() => {
    if (
      requestInfo?.requestFor &&
      requestInfo?.memberCount?.child &&
      requestInfo?.memberCount?.adult &&
      requestInfo?.name &&
      (requestInfo?.profession ||
        (requestInfo?.studyingCource && requestInfo?.studyingPlace)) &&
      requestInfo?.belongsTo &&
      requestInfo?.message
    ) {
      setIsRequestSendDisabled(false);
    } else {
      setIsRequestSendDisabled(true);
    }
  }, [requestInfo]);

  return (
    <>
      <Box>
        <Dialog
          open={isRequestModalOpen}
          onClose={() => setIsRequestModalOpen(false)}
        >
          <Box
            sx={{
              // position: "fixed",
              // left: "5%",
              // right: "5%",
              // top: "10%",
              // bottom: "10%",
              // bgcolor: "white",
              padding: "20px",
              height: "60vh",
            }}
          >
            <Center>
              <Typography
                sx={{
                  color: primaryColor[800],
                  fontWeight: "500",
                  fontSize: "20px",
                  fontFamily: "Calistoga, serif",
                  // textShadow: "1px 1px 4px orange",
                }}
              >
                Requesting Form
              </Typography>
            </Center>
            <Spacer space={15} />
            <Stack>
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    For
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    *
                  </Typography>
                </Stack>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  <Chip
                    label="Family"
                    color="primary"
                    variant={
                      requestInfo?.requestFor === "Family"
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() =>
                      requestInfoDispatch({
                        filter: "requestFor",
                        value: `${
                          requestInfo?.requestFor === "Family" ? "" : "Family"
                        }`,
                      })
                    }
                    sx={{
                      color: `${
                        requestInfo?.requestFor === "Family"
                          ? "white"
                          : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="Bachelor"
                    color="primary"
                    variant={
                      requestInfo?.requestFor === "Bachelor"
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() =>
                      requestInfoDispatch({
                        filter: "requestFor",
                        value: `${
                          requestInfo?.requestFor === "Bachelor"
                            ? ""
                            : "Bachelor"
                        }`,
                      })
                    }
                    sx={{
                      color: `${
                        requestInfo?.requestFor === "Bachelor"
                          ? "white"
                          : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="Student"
                    color="primary"
                    variant={
                      requestInfo?.requestFor === "Student"
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() =>
                      requestInfoDispatch({
                        filter: "requestFor",
                        value: `${
                          requestInfo?.requestFor === "Student" ? "" : "Student"
                        }`,
                      })
                    }
                    sx={{
                      color: `${
                        requestInfo?.requestFor === "Student"
                          ? "white"
                          : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                </Box>
              </Stack>
              <Spacer space={20} />
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Members
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    *
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: grey[700],
                      }}
                    >
                      Children
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      placeholder="#"
                      value={requestInfo?.memberCount?.child}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value.length <= 2) {
                          requestInfoDispatch({
                            type: "updateMemberCount",
                            filter: "child",
                            value: value,
                          });
                        }
                      }}
                      sx={{
                        ...removingDigitsIndicatorsStyle,
                        width: "48px",
                        backgroundColor: "white",
                        "& .MuiInputBase-root": {
                          height: "32px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: grey[500],
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: grey[700],
                      }}
                    >
                      Adults
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      placeholder="#"
                      value={requestInfo?.memberCount?.adult}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value.length <= 2) {
                          requestInfoDispatch({
                            type: "updateMemberCount",
                            filter: "adult",
                            value: value,
                          });
                        }
                      }}
                      sx={{
                        ...removingDigitsIndicatorsStyle,
                        width: "48px",
                        height: "35px",
                        backgroundColor: "white",
                        "& .MuiInputBase-root": {
                          height: "32px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: grey[500],
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Spacer space={12} />
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Name
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    *
                  </Typography>
                </Stack>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  placeholder="Your name"
                  inputProps={{ maxLength: 28 }}
                  value={requestInfo?.name}
                  onChange={(event) =>
                    requestInfoDispatch({
                      filter: "name",
                      value: event.target.value,
                    })
                  }
                />
              </Stack>
              <Spacer space={10} />
              {requestInfo?.requestFor === "Student" ? (
                <>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                        Studying
                      </Typography>
                      <Typography
                        color={primaryColor[900]}
                        sx={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        *
                      </Typography>
                    </Stack>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Your studying course"
                      inputProps={{ maxLength: 28 }}
                      value={requestInfo?.studyingCource}
                      onChange={(event) =>
                        requestInfoDispatch({
                          filter: "studyingCource",
                          value: event.target.value,
                        })
                      }
                    />
                  </Stack>
                  <Spacer space={10} />
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                        College/School/Coaching
                      </Typography>
                      <Typography
                        color={primaryColor[900]}
                        sx={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        *
                      </Typography>
                    </Stack>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Your studying place"
                      inputProps={{ maxLength: 28 }}
                      value={requestInfo?.studyingPlace}
                      onChange={(event) =>
                        requestInfoDispatch({
                          filter: "studyingPlace",
                          value: event.target.value,
                        })
                      }
                    />
                  </Stack>
                </>
              ) : (
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Profession
                    </Typography>
                    <Typography
                      color={primaryColor[900]}
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      *
                    </Typography>
                  </Stack>
                  <TextField
                    type="text"
                    size="small"
                    variant="outlined"
                    placeholder="Your profession"
                    inputProps={{ maxLength: 28 }}
                    value={requestInfo?.profession}
                    onChange={(event) =>
                      requestInfoDispatch({
                        filter: "profession",
                        value: event.target.value,
                      })
                    }
                  />
                </Stack>
              )}
              <Spacer space={10} />
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Belongs To
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    *
                  </Typography>
                </Stack>
                <Select
                  displayEmpty
                  value={requestInfo?.belongsTo}
                  onChange={(event) => {
                    requestInfoDispatch({
                      filter: "belongsTo",
                      value: event.target.value,
                    });
                  }}
                  input={<OutlinedInput />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 68 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  sx={{
                    ...selectInputFieldStyle,
                    width: "100%",
                    height: "42px",
                  }}
                >
                  <MenuItem value="">
                    <Typography fontStyle="italic" sx={{ color: grey[500] }}>
                      Select
                    </Typography>
                  </MenuItem>
                  {statesAndUTs.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Spacer space={10} />
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Message
                  </Typography>
                  <Typography
                    color={primaryColor[900]}
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    *
                  </Typography>
                </Stack>
                <TextField
                  type="text"
                  size="small"
                  multiline
                  rows="4"
                  variant="outlined"
                  placeholder="Your message to owner"
                  value={requestInfo?.message}
                  onChange={(event) =>
                    requestInfoDispatch({
                      filter: "message",
                      value: event.target.value,
                    })
                  }
                />
              </Stack>
              <Spacer space={30} />
              <Button
                variant="contained"
                sx={{ width: "100%px" }}
                disabled={isRequestSendDisabled}
                onClick={handleRequestSend}
              >
                Send
              </Button>
              <Spacer space={20} />
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: grey[500],
  },
};
