import { useState } from "react";
import { updatePaymentStatus } from "../services/update-payment-status";
import { validatePaymentSignature } from "../services/validate-payment-signature";
import { createPaymentOrder } from "../services/create-payment-order";
import {
  LOGO_URL,
  RAZORPAY_KEY_ID_DEV,
  RAZORPAY_KEY_ID_PROD,
} from "../constants/constants";
import { primaryColor } from "../utils/color";
import { isDevMode } from "../utils/is-dev-mode";
import * as Sentry from "@sentry/react";

export const UsePayPost = (user, montlyRent) => {
  const { userInfo, userData } = user;

  const [payPostStatus, setPayPostStatus] = useState({
    isPaymentModalLoading: false,
    isValidatingPaymentSignature: false,
    isSuccess: false,
    isFailed: false,
    isPaymentError: false,
  });
  const [razorpayIds, setRazorpayIds] = useState({
    orderId: "",
    paymentId: "",
  });
  const [isWhiteScreen, setIsWhiteScreen] = useState(false);

  function handlePaymentFailed(postUid) {
    return async function onPaymentFailed(response) {
      console.error("payment failed - mylogs", response.error);
      Sentry.captureException(`Payment: Payment failed - ${response.error}`);

      try {
        await updatePaymentStatus(postUid);
      } catch (err) {
        Sentry.captureException(`Payment: Error while updating payment status when payment failed - ${err}`);
        console.error(
          "error while updating payment status when payment failed - mylogs :",
          err
        );
      }

      setPayPostStatus({
        isPaymentModalLoading: false,
        isValidatingPaymentSignature: false,
        isSuccess: false,
        isFailed: true,
        isPaymentError: false,
      });
      setRazorpayIds({
        orderId: response.error.metadata.order_id,
        paymentId: response.error.metadata.payment_id,
      });
      setIsWhiteScreen(false);
    };
  }

  function handlePaymentSuccess(postUid) {
    return async function onPaymentSuccess(response) {
      const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
        response;

      setPayPostStatus({
        isValidatingPaymentSignature: true,
        isPaymentModalLoading: false,
        isSuccess: false,
        isFailed: false,
        isPaymentError: false,
      });
      setRazorpayIds({
        orderId: razorpay_order_id,
        paymentId: razorpay_payment_id,
      });

      try {
        const validateSignatureRes = await validatePaymentSignature(
          razorpay_payment_id,
          razorpay_signature,
          postUid
        );

        if (validateSignatureRes === "valid") {
          setPayPostStatus({
            isPaymentModalLoading: false,
            isValidatingPaymentSignature: false,
            isSuccess: true,
            isFailed: false,
            isPaymentError: false,
          });
          setIsWhiteScreen(false);
          return;
        }

        Sentry.captureException(`Payment: Signature is invalid or payment updating failed`);
        console.error(
          "Signature is invalid or payment updating failed - mylogs"
        );
        setPayPostStatus({
          isPaymentModalLoading: false,
          isValidatingPaymentSignature: false,
          isSuccess: false,
          isFailed: true,
          isPaymentError: false,
        });
      } catch (err) {
        Sentry.captureException(`Payment: Error while validating payment signature - ${err}`);
        console.error(
          "err while validating signature - ListProperty.js - mylogs :",
          err
        );
        setPayPostStatus({
          isPaymentModalLoading: false,
          isValidatingPaymentSignature: false,
          isSuccess: false,
          isFailed: true,
          isPaymentError: false,
        });
      }
      setIsWhiteScreen(false);
    };
  }

  const handlePay = async (postUid, couponCode) => {
    setPayPostStatus({
      isPaymentModalLoading: true,
      isValidatingPaymentSignature: false,
      isSuccess: false,
      isFailed: false,
      isPaymentError: false,
    });
    setIsWhiteScreen(true);

    try {
      const createPaymentOrderRes = await createPaymentOrder(
        postUid,
        couponCode
      );
      const { order } = createPaymentOrderRes;

      var options = {
        key: isDevMode() ? RAZORPAY_KEY_ID_DEV : RAZORPAY_KEY_ID_PROD,
        order_id: order.id,
        amount: order.amount,
        currency: order.currency,
        name: "Okhla Homes",
        description: `${userInfo?.uid} paying for post ${postUid}`,
        notes: {
          address: "Razorpay Corporate Office",
        },
        prefill: {
          name: userInfo?.displayName,
          email: userInfo?.email,
          contact: userData?.contactNumber,
        },
        theme: {
          color: primaryColor[900],
        },
        image: LOGO_URL,
        handler: handlePaymentSuccess(postUid),
        modal: {
          ondismiss: function () {
            setIsWhiteScreen(false);
          },
        },
      };

      var rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", handlePaymentFailed(postUid));

      setPayPostStatus({
        isPaymentModalLoading: false,
        isValidatingPaymentSignature: false,
        isSuccess: false,
        isFailed: false,
        isPaymentError: false,
      });
      rzp1.open();
    } catch (err) {
      Sentry.captureException(`Payment: Error while creating payment order - ${err}`);
      console.error(
        "err while creating order - ListProperty.js - mylogs :",
        err
      );
      setPayPostStatus({
        isPaymentModalLoading: false,
        isValidatingPaymentSignature: false,
        isSuccess: false,
        isFailed: false,
        isPaymentError: true,
      });
      setIsWhiteScreen(false);
    }
  };

  return {
    payPostStatus,
    setPayPostStatus,
    razorpayIds,
    isWhiteScreen,
    handlePay,
  };
};
