import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { Spacer } from "../components/Spacer";

export const TextOkayModal = ({ value, state, setState, text }) => {
  return (
    <>
      <Box>
        <Dialog
          open={state[value]}
          onClose={() =>
            setState({
              ...state,
              [value]: false,
            })
          }
          fullWidth
        >
          <Box sx={{ padding: "20px" }}>
            <Stack>
              <Typography sx={{ fontSize: "14px" }}>{text}</Typography>
              <Spacer space={20} />
              <Stack sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "24px" }}
                  onClick={() => {
                    setState({
                      ...state,
                      [value]: false,
                    });
                  }}
                >
                  Okay
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
