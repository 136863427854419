import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import InstagramIcon from "../icons/instagram.svg";
import LinkedInIcon from "../icons/linkedin.svg";
import FacebookIcon from "../icons/facebook.svg";
import { Spacer } from "./Spacer";
import { useNavigate } from "react-router-dom";
import { Center } from "./Center";
import { AppVersion } from "./AppVersion";

export const MainFooter = ({ setFooterAtBottom }) => {
  const navigate = useNavigate();

  const footerSytle = setFooterAtBottom
    ? { position: "fixed", bottom: "0px", left: "0px", right: "0px" }
    : {};

  return (
    <>
      <Box
        sx={{
          bgcolor: `${primaryColor[800]}`,
          padding: "20px",
          paddingTop: "30px",
          ...footerSytle,
        }}
      >
        <Stack>
          <Stack
            justifyContent="space-between"
            direction="row"
            sx={{ paddingX: "8px" }}
          >
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/about-us");
                window.scrollTo(0, 0);
              }}
            >
              About Us
            </Typography>
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/faqs");
                window.scrollTo(0, 0);
              }}
            >
              FAQs
            </Typography>
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </Typography>
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/privacy-policy");
                window.scrollTo(0, 0);
              }}
            >
              Privacy Policy
            </Typography>
          </Stack>
          <Spacer space={12} />
          <Stack
            justifyContent="center"
            direction="row"
            sx={{ paddingX: "12" }}
            spacing={2}
          >
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/terms-conditions");
                window.scrollTo(0, 0);
              }}
            >
              Terms and Conditions
            </Typography>
            <Typography
              sx={textStyle}
              onClick={() => {
                navigate("/disclaimer");
                window.scrollTo(0, 0);
              }}
            >
              Disclaimer
            </Typography>
          </Stack>
          <Spacer space={18} />
          <Divider variant="middle" color="white" />
          <Spacer space={8} />
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <IconButton>
              <img width="25px" height="25px" alt="" src={FacebookIcon} />
            </IconButton>
            <IconButton>
              <img width="25px" height="25px" alt="" src={InstagramIcon} />
            </IconButton>
            <IconButton>
              <img width="25px" height="25px" alt="" src={LinkedInIcon} />
            </IconButton>
          </Stack>
          <Spacer space={10} />
          <Center>
            <AppVersion />
          </Center>
        </Stack>
      </Box>
    </>
  );
};

const textStyle = {
  textDecoration: "none",
  color: "white",
  fontSize: "13px",
  fontFamily: "'Roboto Serif', serif",
};
