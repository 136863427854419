import {
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import {
  SERVICE_CHARGE_MODAL_FREE_DESCRIPTION,
  SERVICE_CHARGE_MODAL_PERCENTAGE_DESCRIPTION,
} from "../dictionary/texts";
import LoadingButton from "@mui/lab/LoadingButton";
import { Phone } from "@mui/icons-material";

export const PreRequestFormModal = ({
  isCallNowModalOpen,
  setIsCallNowModalOpen,
  setIsRequestModalOpen,
  postChargePercentage,
  handleDcCallNow,
  postType,
  dcCallNowInfo,
  isRequestApproved,
  ownerContactNumber,
}) => {
  const isFreePost = postChargePercentage === "0";

  return (
    <>
      <Box>
        <Dialog
          open={isCallNowModalOpen}
          onClose={() => setIsCallNowModalOpen(false)}
          fullWidth
        >
          <Box
            sx={{
              // position: "fixed",
              // left: "10%",
              // right: "10%",
              // bgcolor: "white"
              padding: "15px",
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography
                sx={{
                  color: green[800],
                  fontWeight: "300",
                  fontSize: "20px",
                  fontFamily: "Calistoga, serif",
                  textShadow: "1px 1px 4px orange",
                }}
              >
                Get the property
              </Typography>
              <Spacer space={2} />
              <Typography
                sx={{
                  color: primaryColor[800],
                  fontWeight: "600",
                  fontSize: "18px",
                  fontFamily: "'Satisfy', cursive",
                }}
              >
                At Just {postChargePercentage}% of Rent Charge
              </Typography>
              <Spacer space={15} />
              <Stack>
                <Typography
                  sx={{
                    color: primaryColor[800],
                    fontWeight: "500",
                    fontSize: "14px",
                    fontFamily: "'Roboto Serif', serif",
                    textAlign: "center",
                  }}
                >
                  How it works
                </Typography>
                <List disablePadding={true} dense={true}>
                  <ListItem>
                    <Typography
                      sx={{
                        // color: primaryColor[800],
                        fontWeight: "400",
                        fontFamily: "'Roboto Serif', serif",
                        fontSize: "14px",
                        lineHeight: "18p",
                        textAlign: "center",
                      }}
                    >
                      {isFreePost
                        ? SERVICE_CHARGE_MODAL_FREE_DESCRIPTION
                        : SERVICE_CHARGE_MODAL_PERCENTAGE_DESCRIPTION(
                            postChargePercentage
                          )}
                    </Typography>
                  </ListItem>
                </List>
              </Stack>
              <Spacer space={20} />
              {postType === "direct-calling" ? (
                <LoadingButton
                  loading={dcCallNowInfo?.isLoading}
                  variant="contained"
                  sx={{ width: "100%", boxShadow: "6" }}
                  startIcon={<Phone />}
                  onClick={handleDcCallNow}
                >
                  Call Now
                </LoadingButton>
              ) : isRequestApproved ? (
                <Button
                  variant="contained"
                  sx={{ width: "100%", boxShadow: "6" }}
                  startIcon={<Phone />}
                  onClick={() => {
                    window.open(`tel:${ownerContactNumber}`);
                  }}
                >
                  Call Now
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "100%", boxShadow: "6" }}
                  onClick={() => {
                    setIsCallNowModalOpen(false);
                    setIsRequestModalOpen(true);
                  }}
                >
                  Continue
                </Button>
              )}
            </Stack>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
