import {
  Box,
  Chip,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

export const ListPropertyCard = ({ request, navigatedFrom, tabValue }) => {
  const { pathname } = useLocation();
  const isMyRequestsPage = pathname.includes("my-requests");

  return (
    <>
      <Box>
        <ListItem
          disablePadding
          component={Link}
          to={`/view-property?postUid=${request?.postUid}`}
          state={{
            navigatedFrom,
            navigatedData: tabValue,
          }}
          sx={{
            textDecoration: "none",
            color: "black",
            fontFamily: "Neuton, serif",
          }}
        >
          <ListItemButton
            sx={{
              paddingX: "10px",
              paddingY: "8px",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1.5}
              sx={{
                width: "100%",
              }}
            >
              <Box>
                <img
                  style={{
                    width: "95px",
                    height: "65px",
                    // aspectRatio: "16/9",
                    objectFit: "fill",
                    borderRadius: "5px",
                  }}
                  src={request?.basicPostInfo?.mainMedia}
                  alt=""
                />
              </Box>
              <Stack width="100%">
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "'Lumanosimo', serif",
                      color: "black",
                    }}
                  >
                    Rs:{" "}
                    {Math.floor(
                      request?.basicPostInfo?.montlyRent
                    ).toLocaleString("en-IN")}{" "}
                    / Month
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {request?.basicPostInfo?.bhk} bhk
                  </Typography>
                </Stack>
                <Spacer space={1} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack alignItems="flex-start" spacing={-0.5}>
                    <Typography
                      sx={{
                        paddingTop: "3px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {request?.basicPostInfo?.fullName}
                    </Typography>
                    <Typography
                      color={primaryColor[900]}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Neuton, serif",
                      }}
                    >
                      {request?.basicPostInfo?.locality}
                    </Typography>
                  </Stack>
                  {isMyRequestsPage && tabValue === "1" && (
                    <Box>
                      {request?.seen ? (
                        <Visibility
                          sx={{ fontSize: "20px", color: primaryColor[800] }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ fontSize: "20px", color: grey[600] }}
                        />
                      )}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </ListItemButton>
        </ListItem>
      </Box>
    </>
  );
};
